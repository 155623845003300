export interface IUserResponse {
    username: string,
    roles: Array<string>,
    permissions: Array<string>,
    displayName: string,
    givenName: string,
    surname: string,
    jobTitle: string,
    companyName: string,
    cpf: string,
    cnpj: string
};

export const DefaultUserResponse: IUserResponse = {
    username: '',
    roles: [],
    permissions: [],
    displayName: '',
    givenName: '',
    surname: '',
    jobTitle: '',
    companyName: '',
    cpf: '',
    cnpj: ''
};
