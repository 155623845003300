import * as React from 'react';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Alert, Box, Button, CircularProgress, Container, Divider, Fade, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Slide, SlideProps, Snackbar, TextField, Tooltip as MuiTooltip } from '@mui/material';
import { CalcRequestContext, CalcResponseContext } from './TransformerLifespanContext';
import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { ITransLifespanCalculationResponse, ITransLifespanOutputData } from './ITransLifespanCalculationResponse';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import * as ServiceFactory from '../../services/ServiceFactory';
import TransLifespanLoadGraph from './graphCalculation/TransLifespanLoadGraph';
import TransLifespanTempGraph from './graphCalculation/TransLifespanTempGraph';
import TransLifespanGraph from './graphCalculation/TransLifespanGraph';
import TransLifespanConsumedGraph from './graphCalculation/TransLifespanConsumedGraph';
import { useMsal } from '@azure/msal-react';
import { TransitionProps } from '@mui/material/transitions';
import { green } from '@mui/material/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { logWarning } from '../../components/ObservabilityProvider';

interface CalcResults {
    id: number;
    field: string;
    value: number;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
};

enum CalculationTaskResult {
    None,
    Success,
    Failure
}

const alertDurationMs = 3000;

export interface ITransLifespanCalculationStepProps {
    isInputValid: boolean;
};

export default function TransLifespanCalculationStep(props: ITransLifespanCalculationStepProps) {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const { calcResponse, setCalcResponse } = React.useContext(CalcResponseContext);
    const { instance } = useMsal();
    const [ isCalculating, setIsCalculating ] = React.useState(false);
    const [ calculationTaskResult, setCalculationTaskResult ] = React.useState(CalculationTaskResult.None);
   
    const [ successSnackbarState, setSuccessSnackbarState ] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>;
    }>({
        open: false,
        Transition: Fade,
    });

    const [ failureSnackbarState, setFailureSnackbarState ] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>;
    }>({
        open: false,
        Transition: Fade,
    });

    const transLifespanService = ServiceFactory.CreateTransLifespanService(instance);

    const calcResultColumns: GridColDef[] = [
        { field: 'field', headerName: 'Resultado', type: 'string', minWidth: 260 },
        { field: 'value', headerName: 'Valor', type: 'number', align: 'right', flex: 1 }
    ];

    const calcResultWindingColumns: GridColDef[] = [
        { field: 'name', headerName: 'Nome', type: 'string', minWidth: 200 },
        { field: 'hotspotTemperature', headerName: 'Hot-spot (°C)', type: 'number', flex: 1 },
        { field: 'hotspotTime', headerName: 'Instante (min)', type: 'number', flex: 1 },
        { field: 'lifetimeConsumed', headerName: 'Tempo de vida consumido (min)', flex: 1 }
    ];

    const calcResultWindingColumnsGroupingModel: GridColumnGroupingModel = [{
        groupId: 'Enrolamentos',
        description: '',
        children: [{ field: 'name' }, { field: 'hotspotTemperature' }, { field: 'hotspotTime' }, { field: 'lifetimeConsumed' }],
    }];

    React.useEffect(() => {
        if (calculationTaskResult !== CalculationTaskResult.None) {
            const timeoutId = setTimeout(() => {
                setCalculationTaskResult(CalculationTaskResult.None);
            }, alertDurationMs);
    
            return () => clearTimeout(timeoutId);
        }
    }, [calculationTaskResult]);

    const calculateButtonColor = React.useMemo(() => {
        if (!props.isInputValid) {
            return 'warning';
        }

        switch (calculationTaskResult) {
            case CalculationTaskResult.Success: return 'success';
            case CalculationTaskResult.Failure: return 'error';
            default: return 'primary';
        }
    }, [calculationTaskResult]);

    const calcResults = React.useMemo<CalcResults[]>(() => {
        return [{
            id: 0,
            field: 'Tempo de vida consumido (min)',
            value: calcResponse.lifetimeConsumedMin
        }, {
            id: 1,
            field: 'Tempo de vida consumido (dia)',
            value: calcResponse.lifetimeConsumedDay
        }, {
            id: 2,
            field: 'Taxa de envelhecimento (min/min)',
            value: calcResponse.agingRate
        }, {
            id: 3,
            field: 'Vida útil esperada (ano)',
            value: calcResponse.expectedLifespan
        }, {
            id: 4,
            field: 'Temp. máxima do óleo (°C)',
            value: calcResponse.maximumOilTemperature
        }]

    }, [calcResponse]);

    const selectedConditionId = React.useMemo<number>(() => {
        var selected = calcRequest.inputData.oilConditions.find(oc => oc.name === calcRequest.inputData.oilConditionName);
        return selected ? selected.id : 0;
    }, [calcRequest]);

    const calculate = async (): Promise<void> => {
        if (isCalculating || !props.isInputValid) {
            return;
        }

        setIsCalculating(true);

        setCalcRequest({...calcRequest, saveResult: true});

        await transLifespanService.calculate(calcRequest, (response) => {
            if (response?.outputData === null || response?.outputData === undefined) {
                logWarning('Calculation response body is empty.');

                showFailureSnackbar(SlideTransition);
                setCalculationTaskResult(CalculationTaskResult.Failure);
                setIsCalculating(false);

                return;
            }

            const sanitized = sanitizeResponse(response!.outputData);
            setCalcResponse(sanitized);

            showSuccessSnackbar(SlideTransition);
            window.history.pushState(`Edit project ${calcRequest.reference}`, 'TRINSE', `${calcRequest.id}`);
            setCalculationTaskResult(CalculationTaskResult.Success);
            setIsCalculating(false);
        });
    };

    const sanitizeResponse = (data: ITransLifespanOutputData): ITransLifespanOutputData => {
        var response = data;

        response.windingsOutput.forEach((winding, index) => {
            winding.id = index;
        });

        response.calculated = true;

        return response;
    }

    const conditionSelectOnChange = (event: SelectChangeEvent<number>): void => {
        const newState = calcRequest.inputData;
        
        const oilCondition = calcRequest.inputData.oilConditions.find(o => o.id === event.target.value)
        newState.oilConditionName = oilCondition!.name;

        setCalcRequest({...calcRequest, inputData: newState});
    }

    const showSuccessSnackbar =
    (
      Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>
    ): void => {
        setSuccessSnackbarState({open: true, Transition});
    };

    const showFailureSnackbar =
    (
      Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>
    ): void => {
        setFailureSnackbarState({open: true, Transition});
    };

    const onSuccessSnackbarClose = (): void => {
        setSuccessSnackbarState({ ...successSnackbarState, open: false });
    };

    const onFailureSnackbarClose = (): void => {
        setFailureSnackbarState({ ...failureSnackbarState, open: false });
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='lg'>

            <Grid container spacing={1} paddingBottom={3} sx={{display:'flex', alignItems:'center'}} >
                <Grid item xs='auto' sx={{position: 'relative'}}>
                    <Button variant="contained" color={calculateButtonColor} startIcon={<CalculateIcon />} disabled={isCalculating} onClick={ calculate }>
                        Calcular
                    </Button>
                    { !props.isInputValid && (
                        <MuiTooltip title='É necessário corrigir os campos incorretos antes de realizar o cálculo.' arrow>
                            <ErrorOutlineIcon color='warning' sx={{position:'absolute', top:'50%', left:'100%', marginTop:'-12px', marginLeft:'6px', fontSize:'2rem'}} />
                        </MuiTooltip>
                    )}
                    { isCalculating && (
                        <CircularProgress size={24} sx={{color:green[600], position:'absolute', top:'30%', left:'50%', marginLeft:'-6px'}} />
                    )}
                </Grid>
            </Grid>

            <Grid container spacing={3} marginBottom={4}>
                <Grid item xs={4}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="condition-select">Condição para cálculo</InputLabel>
                        <Select name="conditionSelect" labelId='condition-select'
                            value={selectedConditionId} onChange={conditionSelectOnChange}>
                            {calcRequest.inputData.oilConditions.map(condition => 
                                <MenuItem key={condition.id} value={condition.id}>{condition.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Divider sx={{ marginBottom: 4 }} />

            <Grid container spacing={4} sx={{ marginBottom: 4 }}>
                <Grid item sx={{ minHeight: 200 }} xs={5}>
                    <DataGrid
                        rows={calcResults}
                        columns={calcResultColumns}
                        disableRowSelectionOnClick
                        hideFooter={true}
                        style={{ height: '100%' }}
                        />
                </Grid>
                <Grid item xs={5}>
                    <Box component="form" noValidate autoComplete="off">
                        <TextField
                            id='warnings-multiline-static'
                            label='Avisos'
                            multiline
                            rows={8}
                            value={calcResponse.warnings}
                            fullWidth={true}
                            aria-readonly={true}
                        />
                    </Box>
                </Grid>
            </Grid>

            {calcResponse.calculated ?
            (
                <>
                    <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                        <Grid item sx={{ minHeight: 200 }} xs={10}>
                            <DataGrid
                                rows={calcResponse.windingsOutput}
                                columns={calcResultWindingColumns}
                                columnGroupingModel={calcResultWindingColumnsGroupingModel}
                                disableRowSelectionOnClick
                                hideFooter={true}
                                style={{ height: '100%' }}
                                />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                        <Grid item xs={8}>
                            <TransLifespanLoadGraph />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                        <Grid item xs={8}>
                            <TransLifespanTempGraph />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                        <Grid item xs={8}>
                            <TransLifespanGraph />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={{ marginBottom: 4 }}>
                        <Grid item xs={8}>
                            <TransLifespanConsumedGraph />
                        </Grid>
                    </Grid>
                </>
            ) : (<></>)}

            <Snackbar open={successSnackbarState.open} onClose={onSuccessSnackbarClose} TransitionComponent={successSnackbarState.Transition} autoHideDuration={alertDurationMs}>
                <Alert onClose={onSuccessSnackbarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
                    Projeto calculado e salvo!
                </Alert>
            </Snackbar>

            <Snackbar open={failureSnackbarState.open} onClose={onFailureSnackbarClose} TransitionComponent={failureSnackbarState.Transition} autoHideDuration={alertDurationMs}>
                <Alert onClose={onFailureSnackbarClose} severity='error' variant='filled' sx={{ width: '100%' }}>
                    Ocorreu um erro durante o cálculo!
                </Alert>
            </Snackbar>

        </Container>
    );
}
