import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import MainFeaturedPost from './MainFeaturedPost';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import TrinseBackground1 from '../../assets/images/trinse_background_1.jpeg';

const mainFeaturedPost = {
    title: 'TRINSE',
    description:
        "Consultoria e Serviços de Engenharia em Transformadores",
    image: TrinseBackground1,
    imageText: 'main image description',
    linkText: '',
};

export default function Dashboard() {
    return (
        <Container maxWidth="lg">
            <main>
                <MainFeaturedPost post={mainFeaturedPost} />
                <Grid container spacing={4}>

                    <Grid item xs={12} md={6}>
                        <CardActionArea component="a" href="https://trinse.net/">
                            <Card sx={{ display: 'flex' }}>
                                <CardContent sx={{ flex: 1 }}>

                                    <Typography component="h2" variant="h5">
                                        Portal de consultoria e serviços de engenharia
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        13/05/2024
                                    </Typography>
                                    <Typography variant="subtitle1" paragraph>
                                        Visite nosso portal principal para conhecer todos os serviços oferecidos por nossa consultoria.
                                    </Typography>
                                    <Typography variant="subtitle1" color="primary">
                                        Navegar para portal...
                                    </Typography>

                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <CardActionArea component="a" href="/translifespan">
                            <Card sx={{ display: 'flex' }}>
                                <CardContent sx={{ flex: 1 }}>

                                    <Typography component="h2" variant="h5">
                                        Nova ferramenta: vida útil de transformadores
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        13/05/2024
                                    </Typography>
                                    <Typography variant="subtitle1" paragraph>
                                        Conheça nossa nova ferramenta para cálculo de vida útil de transformadores.
                                    </Typography>
                                    <Typography variant="subtitle1" color="primary">
                                        Abrir aplicativo...
                                    </Typography>

                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>
            </main>
        </Container>
    );
}