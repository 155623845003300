import Colors from '../../../styles/Colors.json';

export const GetGraphColor = (position: number): string => {
    switch (position) {
        case 1: return Colors.complementary[600].color;
        case 2: return Colors.analogous[700].color;
        case 3: return Colors.triadic[900].color;
        case 4: return Colors.additional[100].color;
    }

    return Colors.brand[900].color;
};
