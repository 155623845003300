import React from 'react'
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import GraphAge from '../../assets/images/graph_age.png';
import GraphAgingSpeed from '../../assets/images/graph_aging_speed.png';
import GraphTemperature from '../../assets/images/graph_temp.png';
import { loginRequest } from '../../authConfig';
import { useMsal } from '@azure/msal-react';
import { logException } from '../../components/ObservabilityProvider';

export default function TransLifespanMain() {
    const { instance } = useMsal();

    const handleLoginClick = async (): Promise<void> => {
        await instance.loginRedirect(loginRequest).catch((error) => logException(error));
    };

    return (
        <Container maxWidth="lg">
            <Paper sx={{
                position: 'relative',
                backgroundColor: 'white',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginTop: 4}}>
                <Box sx={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0}} />
                <Grid container display={'flex'}>
                    <Grid item md={12}>
                        <Box sx={{position: 'relative', p: { xs: 3, md: 6 }}} >
                            <Typography component="h2" variant="h5" gutterBottom>
                                Cálculo de vida útil
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                Bem-vindo à nossa ferramenta avançada de cálculo de vida útil para transformadores. Nós entendemos a importância crítica dos transformadores em diversos setores, e é por isso que desenvolvemos esta ferramenta inovadora para ajudar você a maximizar a eficiência e a longevidade dos seus equipamentos.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4} flex={1}>
                        <Box sx={{p: { xs: 3, md: 4}}}>
                            <Box component='img' src={GraphTemperature} sx={{maxWidth: '100%'}} />
                            <Typography component="h4" variant="h6">
                                Elevação de temperatura
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                Potencialize a gestão do seu transformador com nosso gráfico de temperatura teórica para óleo e enrolamento. Desenvolvido com base nas especificações do seu transformador, este recurso exclusivo proporciona uma representação visual precisa das variações térmicas, permitindo decisões estratégicas para maximizar o desempenho e prolongar a vida útil do seu equipamento.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4} flex={1}>
                        <Box sx={{p: { xs: 3, md: 4}}}>
                            <Box component='img' src={GraphAgingSpeed} sx={{maxWidth: '100%'}} />
                            <Typography component="h4" variant="h6">
                                Taxa de envelhecimento
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                Aprimore a gestão do seu transformador com nosso gráfico teórico de taxa de envelhecimento. Desenvolvido com base nas especificações do seu equipamento, este recurso exclusivo oferece uma representação visual precisa das projeções de envelhecimento, permitindo decisões estratégicas para maximizar a durabilidade e o desempenho do seu transformador.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box sx={{p: { xs: 3, md: 4}}}>
                            <Box component='img' src={GraphAge} sx={{maxWidth: '100%'}} />
                            <Typography component="h4" variant="h6">
                                Tempo de vida consumido
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                Aperfeiçoe a gestão do seu transformador com nosso gráfico teórico de tempo de vida consumido. Desenvolvido com base nas especificações do seu equipamento, este recurso exclusivo oferece uma representação visual precisa das projeções do tempo de vida já consumido, permitindo decisões estratégicas para otimizar a durabilidade e o desempenho do seu transformador.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <Typography sx={{paddingTop: { xs: 2, md: 3}}} component="h4" variant="h6">
                            Cadastre-se para começar a realizar cálculos
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                        <Box sx={{paddingTop: { xs: 2, md: 3}, paddingBottom: { xs: 3, md: 6}}}>
                            <Button variant="contained" startIcon={<LoginIcon />} onClick={handleLoginClick}>Entrar</Button>
                        </Box>
                    </Grid>

                </Grid>
            </Paper>
        </Container>
    );
}