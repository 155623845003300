import { createContext } from "react";
import { DefaultTransLifespanCalculationRequest, ITransLifespanCalculationRequest } from "./ITransLifespanCalculationRequest";
import { DefaultTransLifespanCalculationResponse, ITransLifespanCalculationResponse } from "./ITransLifespanCalculationResponse";

export const CalcRequestContext = createContext({
    calcRequest: DefaultTransLifespanCalculationRequest,
    setCalcRequest: (() => {}) as React.Dispatch<React.SetStateAction<ITransLifespanCalculationRequest>>
});

export const CalcResponseContext = createContext({
    calcResponse: DefaultTransLifespanCalculationResponse,
    setCalcResponse: (() => {}) as React.Dispatch<React.SetStateAction<ITransLifespanCalculationResponse>>
});
