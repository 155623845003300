import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AbilityContext } from './Can';

export interface IIsAuthorizedProps {
    to: string,
    the: string,
    children: JSX.Element
}

export default function EnsureIsAuthorized(props: IIsAuthorizedProps) {
    const ability = React.useContext(AbilityContext);
    
    return (
        <>
            <AuthenticatedTemplate>
                {ability.cannot(props.to, props.the)
                    ? <Navigate replace to="/forbidden" />
                    : props.children
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Navigate replace to="/unauthorized" />
            </UnauthenticatedTemplate>
        </>
    );
}
