import * as React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Container, Grid, IconButton, InputBase, Paper, } from '@mui/material';
import { CalcRequestContext } from './TransformerLifespanContext';
import { DataGrid, GridColDef, GridColumnGroupingModel, GridRowModel } from '@mui/x-data-grid';
import * as Opr from '../../utils/CustomOperators';
import { IOilConditionData } from './ITransLifespanCalculationRequest';

interface WindingConditions {
    id: number;
    idCondition: number;
    idWinding: number;
    name: string;
    windingName: string;
    gradient: number;
    loadLosses: number;
}

export default function TransLifespanConditionStep() {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const [ editingPosition, setEditingPosition ] = React.useState(Number.NaN);
    const [ currentConditionName, setCurrentConditionName ] = React.useState('');

    const conditionInputRef = React.useRef(''); // creating a reference for TextField Component

    const oilConditionsColumns: GridColDef[] = [
        { field: Opr.nameOf((_: IOilConditionData) => _.name), headerName: 'Condição', type: 'string', width: 200 },
        { field: Opr.nameOf((_: IOilConditionData) => _.avgTempRise), headerName: 'Elev. média (°C)', type: 'number', width: 150, editable: true },
        { field: Opr.nameOf((_: IOilConditionData) => _.topOilTempRise), headerName: 'Elev. topo (°C)', type: 'number', width: 150, editable: true },
        { field: Opr.nameOf((_: IOilConditionData) => _.loadLosses), headerName: 'Perdas em carga (W)', type: 'number', width: 150, editable: true },
        { field: Opr.nameOf((_: IOilConditionData) => _.noLoadLosses), headerName: 'Perdas em vazio (W)', type: 'number', width: 150, editable: true },
        { field: Opr.nameOf((_: IOilConditionData) => _.coolingMode), headerName: 'Modo de refrigeração', type: 'singleSelect', width: 150, editable: true,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.description,
            valueOptions: [
                { code: 0, description: 'Transformador pequeno' },
                { code: 1, description: 'ONAN' },
                { code: 2, description: 'ONAN (obstruido)' },
                { code: 3, description: 'ONAF' },
                { code: 4, description: 'ONAF (obstruido)' },
                { code: 5, description: 'OF' },
                { code: 6, description: 'OF (obstruido)' },
                { code: 7, description: 'OD' }]},
    ];

    const oilConditionsColumnsGroupingModel: GridColumnGroupingModel = [{
        groupId: 'Óleo',
        description: '',
        children: [
            { field: Opr.nameOf((_: IOilConditionData) => _.name) },
            { field: Opr.nameOf((_: IOilConditionData) => _.avgTempRise) },
            { field: Opr.nameOf((_: IOilConditionData) => _.topOilTempRise) },
            { field: Opr.nameOf((_: IOilConditionData) => _.loadLosses) },
            { field: Opr.nameOf((_: IOilConditionData) => _.noLoadLosses) },
            { field: Opr.nameOf((_: IOilConditionData) => _.coolingMode) }],
    }];

    const windingConditionsColumns: GridColDef[] = [
        { field: 'name', headerName: 'Condição', type: 'string', width: 200 },
        { field: 'windingName', headerName: 'Enrolamento', type: 'string', width: 200 },
        { field: 'gradient', headerName: 'Gradiente (°C)', type: 'number', width: 150, editable: true },
        { field: 'loadLosses', headerName: 'Perdas em carga (W))', type: 'number', width: 150, editable: true },
    ];

    const windingConditionsColumnsGroupingModel: GridColumnGroupingModel = [{
        groupId: 'Enrolamentos',
        description: '',
        children: [{ field: 'name' }, { field: 'windingName' }, { field: 'gradient' }, { field: 'loadLosses' }],
    }];

    const windingConditions = React.useMemo<WindingConditions[]>(() => {
        let id = 0;

        return calcRequest.inputData.windings.flatMap(winding => {
            return winding.conditions.map(condition => {
                return {
                    id: id++,
                    idCondition: condition.id,
                    idWinding: winding.id,
                    name: condition.name,
                    windingName: winding.name,
                    gradient: condition.gradient,
                    loadLosses: condition.loadLosses
                };
            })
        });
    }, [calcRequest]);

    const addOrChangeCondition = (): void => {
        if (Number.isNaN(editingPosition)) {
            addCondition();
        } else {
            changeCondition();
        }
    };

    const addCondition = (): void => {
        addOilCondition(currentConditionName);
        setCurrentConditionNameAndInput('');
    };

    const changeCondition = (): void => {
        changeOilConditionName(editingPosition, currentConditionName);
        setEditingPosition(Number.NaN);
        setCurrentConditionNameAndInput('');
    };

    const editCondition = (index: number): void => {
        setCurrentConditionNameAndInput(calcRequest.inputData.oilConditions[index].name);
        setEditingPosition(index);
    };

    const deleteCondition = (index: number): void => {
        removeOilCondition(index);
        setEditingPosition(Number.NaN);
        setCurrentConditionNameAndInput('');
    };

    const setCurrentConditionNameAndInput = (value: string): void => {
        const input = conditionInputRef as any;
        input.current.value = value;

        setCurrentConditionName(value);
    };

    const addOilCondition = (name: string): void => {
        const newState = calcRequest.inputData;
        const newConditionId = Math.max(...newState.oilConditions.map(o => o.id)) + 1;

        newState.oilConditions = [...newState.oilConditions, {
            id: newConditionId,
            name: name,
            avgTempRise: 99,
            loadLosses: 9999,
            topOilTempRise: 99,
            noLoadLosses: 0,
            coolingMode: 0
        }];

        newState.windings.forEach(function (winding) {
            winding.conditions = [...winding.conditions, {
                id: newConditionId,
                name: name,
                gradient: 99,
                loadLosses: 9999
            }];
        });

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const changeOilConditionName = (position: number, newName: string): void => {
        const newState = calcRequest.inputData;

        newState.oilConditionName = newName;
        newState.oilConditions[position].name = newName;

        newState.windings.forEach(function (winding) {
            winding.conditions[position].name = newName;
        });

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const removeOilCondition = (position: number): void => {
        const newState = calcRequest.inputData;

        newState.oilConditions = [...newState.oilConditions];
        newState.oilConditions.splice(position, 1);

        newState.windings.forEach(function (winding) {
            winding.conditions.splice(position, 1);
        });

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const conditionInputkeyPress = (event: any): void => {
        if (event.keyCode == 13) {
            addOrChangeCondition();
        }
    };

    const conditionInputOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setCurrentConditionName(event.target.value.trim());
    };

    const oilConditionsProcessRowUpdate = (newRow: GridRowModel): GridRowModel => {
        const newState = calcRequest.inputData;

        const oilCondition = newState.oilConditions.find(o => o.id === newRow.id);
        if (oilCondition) {
            oilCondition.avgTempRise = newRow.avgTempRise;
            oilCondition.topOilTempRise = newRow.topOilTempRise;
            oilCondition.loadLosses = newRow.loadLosses;
            oilCondition.noLoadLosses = newRow.noLoadLosses;
            oilCondition.coolingMode = newRow.coolingMode;
        }

        setCalcRequest({...calcRequest, inputData: newState});

        return newRow;
    };

    const windingConditionsProcessRowUpdate = (newRow: GridRowModel): GridRowModel => {
        const newState = calcRequest.inputData;

        const winding = newState.windings.find(w => w.id === newRow.idWinding);
        const condition = winding?.conditions.find(c => c.id === newRow.idCondition);

        if (condition) {
            condition.gradient = newRow.gradient;
            condition.loadLosses = newRow.loadLosses;
        }

        setCalcRequest({...calcRequest, inputData: newState});

        return newRow;
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='lg'>

            <Grid container spacing={1} marginBottom={4}>
                <Grid item xs={4}>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
                        <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Nome da condição" aria-label='condition-input' onChange={conditionInputOnChange} inputRef={conditionInputRef} onKeyDown={conditionInputkeyPress}/>
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="condition-add-btn" disabled={!currentConditionName.trim()} onClick={addOrChangeCondition}>
                            {Number.isNaN(editingPosition) ? <AddCircleIcon /> : <CheckCircleIcon />}
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={7} sx={{ alignItems: 'center', display: 'flex' }}>
                    {calcRequest.inputData.oilConditions.map((oilCondition, index) => 
                        calcRequest.inputData.oilConditions.length === 1
                            ? <Chip key={index} sx={{ marginLeft: 2 }} label={oilCondition.name} onClick={() => editCondition(index)}/>
                            : <Chip key={index} sx={{ marginLeft: 2 }} label={oilCondition.name} onClick={() => editCondition(index)} onDelete={() => deleteCondition(index)}/>
                    )}
                </Grid>
            </Grid>

            <Grid container spacing={3}>

                <Grid sx={{ height: 250}} item xs>
                    <DataGrid
                        rows={calcRequest.inputData.oilConditions}
                        columns={oilConditionsColumns}
                        columnGroupingModel={oilConditionsColumnsGroupingModel}
                        initialState={{ pagination: { paginationModel: { pageSize: 5 }} }}
                        isRowSelectable={params => params.row.id !== 0}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        processRowUpdate={oilConditionsProcessRowUpdate}
                         />
                </Grid>

                <Grid sx={{ height: 250}} item xs={9}>
                    <DataGrid
                        rows={windingConditions}
                        columns={windingConditionsColumns}
                        columnGroupingModel={windingConditionsColumnsGroupingModel}
                        initialState={{ pagination: { paginationModel: { pageSize: 5 }} }}
                        isRowSelectable={params => params.row.id !== 0}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        processRowUpdate={windingConditionsProcessRowUpdate}
                         />
                </Grid>

            </Grid>
        </Container>
    );
}
