import axios from "axios";
import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { BaseService } from "./BaseService";
import { ILoginResponse } from "./models/ILoginResponse";
import { IUserResponse } from "./models/IUserResponse";
import { IAllUsersResponse } from "../views/users/IAllUsersResponse";
import { IUserProfileRequest } from "./models/IUserProfileRequest";
import { logException } from "../components/ObservabilityProvider";

export type UserLoginResponseFunction = (response: void | ILoginResponse) => void;

export type UserResponseFunction = (response: void | IUserResponse) => void;

export type UsersResponseFunction = (response: void | IAllUsersResponse) => void;

export type UserPutFunction = (success: boolean) => void;

export interface IUserService {
    get(fullProfile: boolean, predicate: UserResponseFunction): Promise<void>;
    put(userProfileRequest: IUserProfileRequest, predicate: UserPutFunction): Promise<void>;
    getUsers(predicate: UsersResponseFunction): Promise<void>;
    login(input: AuthenticationResult, predicate: UserLoginResponseFunction): Promise<void>;
}

export class UserService extends BaseService implements IUserService {
    constructor(msalClient: IPublicClientApplication) {
        super(msalClient);
    }

    public async get(fullProfile: boolean, predicate: UserResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .get(
                'app/user',
                {
                    headers: {
                        Authorization: token
                    },
                    params: {
                        'fullProfile': fullProfile
                    }
                })
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    }

    public async put(userProfileRequest: IUserProfileRequest, predicate: UserPutFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .put(
                'app/user',
                userProfileRequest,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .catch((error) => { logException(error); })
            .then((response) => predicate(response !== null && response !== undefined && response.status === 204));
    }

    public async getUsers(predicate: UsersResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .get(
                'app/user/all',
                {
                    headers: {
                        Authorization: token
                    }
                })
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    }

    public async login(input: AuthenticationResult, predicate: UserLoginResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .post<AuthenticationResult, any>(
                'app/user/login',
                input,
                {
                    headers: {
                        Authorization: token
                    }
                })
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    }
}
