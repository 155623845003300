import axios from "axios";
import { ITransLifespanCalculationRequest, ITransLifespanNewProjectRequest } from "../views/transLifespan/ITransLifespanCalculationRequest";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ITransLifespanCalculationResponse } from "../views/transLifespan/ITransLifespanCalculationResponse";
import { ITransLifespanProjectsResponse } from "../views/transLifespan/ITransLifespanProjectsResponse";
import { ITransLifespanCalculationProject } from "../views/transLifespan/ITransLifespanCalculationProject";
import { BaseService } from "./BaseService";
import { logException } from "../components/ObservabilityProvider";

export type TransLifespanCalculationResponseFunction = (response: void | ITransLifespanCalculationResponse) => void;

export type TransLifespanCalculationProjectFunction = (response: void | ITransLifespanCalculationProject) => void;

export type TransLifespanProjectsResponseFunction = (response : void | ITransLifespanProjectsResponse) => void;

export type TransLifespanCreateResponseFunction = () => void;

export type TransLifespanDeleteResponseFunction = () => void;

export interface ITransLifespanService {
    calculate(request: ITransLifespanCalculationRequest, predicate: TransLifespanCalculationResponseFunction): Promise<void>;
    getProject(id: string, predicate: TransLifespanCalculationProjectFunction): Promise<void>;
    createProject(request: ITransLifespanNewProjectRequest, predicate: TransLifespanCreateResponseFunction): Promise<void>;
    getProjects(request: ITransLifespanProjectsRequest, predicate: TransLifespanProjectsResponseFunction): Promise<void>;
    deleteProject(projectId: string, predicate: TransLifespanDeleteResponseFunction): Promise<void>;
};

export interface ITransLifespanProjectsRequest {
    take: number;
    skip: number;
};

export class TransLifespanService extends BaseService implements ITransLifespanService {
    constructor(msalClient: IPublicClientApplication) {
        super(msalClient);
    };

    public async calculate(request: ITransLifespanCalculationRequest, predicate: TransLifespanCalculationResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .post<ITransLifespanCalculationRequest, any>(
                'app/translifespan/project/calculate',
                request,
                { headers: {
                    Authorization: token
                }})
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    };

    public async getProject(id: string, predicate: TransLifespanCalculationProjectFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .get<ITransLifespanCalculationProject>(
                `app/translifespan/project/${id}`,
                { headers: {
                    Authorization: token
                }})
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    };

    public async createProject(request: ITransLifespanNewProjectRequest, predicate: TransLifespanCreateResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .post(
                'app/translifespan/project',
                request,
                { headers: {
                    Authorization: token
                }})
            .catch((error) => { logException(error); })
            .then(() => predicate());
    };

    public async getProjects(request: ITransLifespanProjectsRequest, predicate: TransLifespanProjectsResponseFunction): Promise<void> {
        const token = await super.getToken();
        
        return axios
            .get<ITransLifespanProjectsResponse>(
                `app/translifespan/project/all?take=${request.take}&skip=${request.skip}`,
                { headers: {
                    Authorization: token
                }})
            .catch((error) => { logException(error); })
            .then((response) => predicate(response?.data));
    };

    public async deleteProject(projectId: string, predicate: TransLifespanDeleteResponseFunction): Promise<void> {
        const token = await super.getToken();

        return axios
            .delete(
                `app/translifespan/project/${projectId}`,
                { headers: {
                    Authorization: token
                }})
            .catch((error) => { logException(error); })
            .then(() => predicate());
    };
}
