import * as React from 'react'
import * as ServiceFactory from '../../services/ServiceFactory';
import * as Opr from '../../utils/CustomOperators';
import { useMsal } from '@azure/msal-react';
import { DefaultAllUsersResponse, IAllUsersResponse } from './IAllUsersResponse';
import { Box, Chip, Container, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import EnsureIsAuthorized from '../../components/EnsureIsAuthorized';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IUserInfoResponse } from './IUserInfoResponse';
import PeopleIcon from '@mui/icons-material/People';
import LocalDataTooltip from '../transLifespan/components/LocalDataTooltip';
import { logWarning } from '../../components/ObservabilityProvider';

export default function AllUsersMain() {
    const [ allUsers, setAllUsers ] = React.useState<IAllUsersResponse>(DefaultAllUsersResponse);
    const [ userListLoading, setUserListLoading] = React.useState(true);
    const { instance } = useMsal();

    const userService = ServiceFactory.CreateUserService(instance);

    const columns: GridColDef[] = [
        { field: Opr.nameOf((_: IUserInfoResponse) => _.identityEmail), headerName: 'Email', type: 'string', flex: 1 },
        { field: Opr.nameOf((_: IUserInfoResponse) => _.roles), headerName: 'Funções', type: 'string', width: 250,
            renderCell: params => (params.value as Array<string>).map((role, index) =>
                <Chip key={index} sx={{mr:1}} label={role} variant="outlined"/>)
        },
        { field: Opr.nameOf((_: IUserInfoResponse) => _.lastLoginAt), headerName: 'Último login', type: 'string', width: 150,
            renderCell: params => <LocalDataTooltip date={params.value} />
        },
    ];

    React.useEffect(() => {
        setUserListLoading(true);

        userService.getUsers((response) => {
            if (response === null || response === undefined) {
                logWarning('User list response body is empty.');
                setUserListLoading(false);
                return;
            }

            response.users.forEach((user, index) => {
                user.id = index;
            });

            setAllUsers(response);
            setUserListLoading(false);
        })
    }, []);

    return (
        <EnsureIsAuthorized to='manage' the='users'>
            <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='xl'>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <Grid container sx={{pb: 2}} alignItems='center'>
                        <Grid item sx={{pr:2}}>
                            <PeopleIcon fontSize='large' />
                        </Grid>
                        <Grid item>
                            <Typography variant='h4' fontWeight={600}>
                                Lista de usuários
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ width: '100%', position: 'relative', height: 'auto' }}>
                        {userListLoading
                            ? <Grid container sx={{ pt:5, justifyContent:'center'}}>
                                <LinearProgress sx={{width:'85%'}} />
                            </Grid>
                            : <Box sx={{ height: 500 }}>
                                <DataGrid
                                    rows={allUsers.users}
                                    columns={columns}
                                    initialState={{ pagination: { paginationModel: { pageSize: 20 }} }}
                                    pageSizeOptions={[20]}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        } 
                    </Box>

                </Paper>
            </Container>
        </EnsureIsAuthorized>        
    );
}
