import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Container, Grid, MenuItem, Select } from '@mui/material';
import { CalcRequestContext } from './TransformerLifespanContext';
import { ITransLifespanCalculationRequest } from './ITransLifespanCalculationRequest';
import LifespanStandardInput from './components/LifespanStandardInput';

export interface ITransLifespanBasicDataStepProps {
    setErrorState: (isErrorState: boolean) => void;
}

enum OilInsulatingSystem {
    MineralOil,
    Ester
};

enum PaperInsulatingSystem {
    Common,
    Thermostabilized,
    Aramid,
    Hybrid
};

const getSelectedOilInsulatingSystem = (calcRequest: ITransLifespanCalculationRequest): OilInsulatingSystem => {
    if (calcRequest.inputData.insulatingSystem === 2 || calcRequest.inputData.insulatingSystem === 3) {
        return OilInsulatingSystem.Ester;
    }

    return OilInsulatingSystem.MineralOil;
};

const getSelectedPaperInsulatingSystem = (calcRequest: ITransLifespanCalculationRequest): PaperInsulatingSystem => {
    if (calcRequest.inputData.insulatingSystem === 3 || calcRequest.inputData.insulatingSystem === 5) {
        return PaperInsulatingSystem.Thermostabilized;
    }
    if (calcRequest.inputData.insulatingSystem === 6) {
        return PaperInsulatingSystem.Aramid;
    }
    if (calcRequest.inputData.insulatingSystem === 7) {
        return PaperInsulatingSystem.Hybrid;
    }

    return PaperInsulatingSystem.Common;
};

const isProjectReferenceInvalid = (reference: string): boolean => {
    return !reference || reference.trim().length === 0;
};

const isTransformerPowerkVAInvalid = (power: number): boolean => {
    return power <= 0;
};

export default function TransLifespanBasicDataStep(props: ITransLifespanBasicDataStepProps) {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const [ insulatingSystemOil, setInsulatingSystemOil ] = React.useState(getSelectedOilInsulatingSystem(calcRequest));
    const [ insulatingSystemPaper, setInsulatingSystemPaper ] = React.useState(getSelectedPaperInsulatingSystem(calcRequest));
    const [ projectReferenceError, setProjectReferenceError ] = React.useState(isProjectReferenceInvalid(calcRequest.reference));
    const [ transformerPowerkVAError, setTransformerPowerkVAError ] = React.useState(isTransformerPowerkVAInvalid(calcRequest.inputData.transformerPowerkVA));

    React.useEffect(() => {
        props.setErrorState(projectReferenceError || transformerPowerkVAError);
    }, [projectReferenceError, transformerPowerkVAError]);

    React.useEffect(() => {
        if (insulatingSystemOil === OilInsulatingSystem.Ester
            && insulatingSystemPaper !== PaperInsulatingSystem.Common
            && insulatingSystemPaper !== PaperInsulatingSystem.Thermostabilized) {

            setInsulatingSystemPaper(PaperInsulatingSystem.Common);
        } else {
            selectInsulatingSystem();
        }
    }, [insulatingSystemOil]);

    React.useEffect(() => {
        selectInsulatingSystem();
    }, [insulatingSystemPaper]);

    const selectInsulatingSystem = (): void => {
        let insulatingSystem = 0;

        if (insulatingSystemOil === OilInsulatingSystem.MineralOil) {
            if (insulatingSystemPaper === PaperInsulatingSystem.Common) {
                insulatingSystem = 4;
            }
            if (insulatingSystemPaper === PaperInsulatingSystem.Thermostabilized) {
                insulatingSystem = 5;
            }
            if (insulatingSystemPaper === PaperInsulatingSystem.Aramid) {
                insulatingSystem = 6;
            }
            if (insulatingSystemPaper === PaperInsulatingSystem.Hybrid) {
                insulatingSystem = 7;
            }
        }

        if (insulatingSystemOil === OilInsulatingSystem.Ester) {
            if (insulatingSystemPaper === PaperInsulatingSystem.Common) {
                insulatingSystem = 2;
            }
            if (insulatingSystemPaper === PaperInsulatingSystem.Thermostabilized) {
                insulatingSystem = 3;
            }
        }

        const inputData = calcRequest.inputData;
        inputData.insulatingSystem = insulatingSystem;
        setCalcRequest({ ...calcRequest, inputData });
    };

    const onProjectReferenceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const reference = event.target.value as string;
        setCalcRequest({...calcRequest, reference: reference});
        setProjectReferenceError(isProjectReferenceInvalid(reference));
    };

    const onTransformerPowerkVAChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const power = +event.target.value;
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, transformerPowerkVA: power}});
        setTransformerPowerkVAError(isTransformerPowerkVAInvalid(power));
    };

    return (
        <Container component="form" sx={{ paddingTop: 6, paddingBottom: 3 }} noValidate autoComplete="off">
            <Grid container spacing={3}>

                <Grid item xs={4}>
                    <LifespanStandardInput id='projectReference-textField' type='string'
                        error={projectReferenceError}
                        label='Referência do projeto'
                        value={calcRequest.reference}
                        onChange={onProjectReferenceChange}
                        errorTooltip='Referência não pode estar em branco.'
                    />
                </Grid>

                <Grid item xs={4}>
                    <LifespanStandardInput id='transformerPowerkVA-textField' type='number'
                        error={transformerPowerkVAError}
                        label='Potência do transformador'
                        value={calcRequest.inputData.transformerPowerkVA}
                        onChange={onTransformerPowerkVAChange}
                        helperText='kVA'
                        errorTooltip='Potência deve ser maior que zero.'
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant='standard' fullWidth>
                        <InputLabel htmlFor='insulatingSystemOil-select'>Tipo do líquido isolante</InputLabel>
                        <Select name='insulatingSystemOil' labelId='insulatingSystemOil-select'
                            value={insulatingSystemOil} onChange={evt => setInsulatingSystemOil(evt.target.value as OilInsulatingSystem)}>
                            <MenuItem value={OilInsulatingSystem.MineralOil}>Óleo mineral</MenuItem>
                            <MenuItem value={OilInsulatingSystem.Ester}>Ester (óleo vegetal)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant='standard' fullWidth>
                        <InputLabel htmlFor='insulatingSystemPaper-select'>Tipo da isolação sólida</InputLabel>
                        <Select name='insulatingSystemPaper' labelId='insulatingSystemPaper-select'
                            value={insulatingSystemPaper} onChange={evt => setInsulatingSystemPaper(evt.target.value as PaperInsulatingSystem)}>
                            <MenuItem value={PaperInsulatingSystem.Common}>Comum</MenuItem>
                            <MenuItem value={PaperInsulatingSystem.Thermostabilized}>Termoestabilizado</MenuItem>
                            {insulatingSystemOil === OilInsulatingSystem.MineralOil &&
                                <MenuItem value={PaperInsulatingSystem.Aramid}>Aramida</MenuItem>
                            }
                            {insulatingSystemOil === OilInsulatingSystem.MineralOil &&
                                <MenuItem value={PaperInsulatingSystem.Hybrid}>Híbrido celulose/aramida</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </Container>
    );
}