import * as React from 'react';
import { Alert, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { ITransLifespanProjectDetailsResponse } from './ITransLifespanProjectsResponse';
import * as ServiceFactory from '../../services/ServiceFactory';
import { useMsal } from '@azure/msal-react';
import * as Opr from '../../utils/CustomOperators';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocalDataTooltip from './components/LocalDataTooltip';
import { logWarning } from '../../components/ObservabilityProvider';

export default function TransLifespanProjectLoad() {
    const [ loading, setLoading ] = React.useState(true);
    const [ projects, setProjects ] = React.useState<ITransLifespanProjectDetailsResponse[]>([]);
    const [ hasSelection, setHasSelection ] = React.useState(false);
    const [ deleteDialogOpen, setDeleteDialogOpen ] = React.useState(false);
    const { instance } = useMsal();
    const navigate = useNavigate();

    const transLifespanService = ServiceFactory.CreateTransLifespanService(instance);

    const columns: GridColDef[] = [
        { field: Opr.nameOf((_: ITransLifespanProjectDetailsResponse) => _.reference), headerName: 'Referência', type: 'string', width: 240 },
        { field: 'Description', headerName: 'Descrição', flex: 1 },
        { field: Opr.nameOf((_: ITransLifespanProjectDetailsResponse) => _.createdAt), headerName: 'Criado', type: 'string', width: 150,
            renderCell: params => <LocalDataTooltip date={params.value} />
        },
        { field: Opr.nameOf((_: ITransLifespanProjectDetailsResponse) => _.modifiedAt), headerName: 'Modificado', type: 'string', width: 150,
            renderCell: params => <LocalDataTooltip date={params.value} />
        },
        { field: Opr.nameOf((_: ITransLifespanProjectDetailsResponse) => _.isCalculated), headerName: 'Calculado', type: 'boolean',
            renderCell: params => params.value as boolean
                ? <CheckIcon />
                : <CloseIcon />
        },
    ];

    React.useEffect(() => {
        loadProjects();
    }, []);

    const loadProjects = (): Promise<void> => {
        setLoading(true);

        return transLifespanService.getProjects({take: 30, skip: 0}, (response) => {
            if (response !== null && response !== undefined) {
                setProjects(response.transLifespanProjectDetails);
            } else {
                logWarning('Project list response body is empty.');
            }

            setLoading(false);
        });
    };

    const openSelectedProject = (params: GridRowParams): void => {
        navigate(`/translifespan/${(params.row as ITransLifespanProjectDetailsResponse).id}`);
    };

    const openNewProject = (): void => {
        navigate('/translifespan/new');
    };

    const copySelectedProjets = async (): Promise<void> => {
        setLoading(true);

        await Promise.all(projects
            .filter(p => p.selected)
            .map(async p => {

                // TODO - refactor to single API call (with new 'copy project' endpoint)
                await transLifespanService.getProject(p.id, async (response) => {
                    if (response === null || response === undefined) {
                        return;
                    }

                    return await transLifespanService.createProject({
                        reference: response.reference + '-copia',
                        inputData: response.inputData
                    }, () => { });
                });
            }));

        loadProjects();
    };

    const deleteSelectedProjets = (): void => {
        setDeleteDialogOpen(true);
    };

    const setSelectedRows = (evt: GridRowSelectionModel): void => {
        const newState = projects;

        newState.forEach(w => {
            w.selected = evt.includes(w.id);
        });

        setHasSelection(evt.length > 0)
        setProjects(newState);
    };

    const handleDeleteDialogClose = (): void => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteProjects = async (): Promise<void> => {
        setLoading(true);
        setDeleteDialogOpen(false);

        await Promise.all(projects
            .filter(p => p.selected)
            .map(async p => await transLifespanService.deleteProject(p.id, () => {})));

        loadProjects();
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='lg'>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={1} paddingBottom={3}>
                    <Grid item xs='auto'>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={openNewProject}>Novo</Button>
                    </Grid>
                    <Grid item xs='auto'>
                        <Button variant="contained" startIcon={<ContentCopyIcon />} disabled={!hasSelection} onClick={copySelectedProjets}>Copiar</Button>
                    </Grid>
                    <Grid item xs='auto'>
                        <Button variant="contained" startIcon={<DeleteIcon />} disabled={!hasSelection} onClick={deleteSelectedProjets}>Excluir</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={4} justifyContent='center'>
                    <Grid sx={{ height: 500 }} item xs>
                        { loading &&
                            <CircularProgress />
                        }
                        { !loading && projects.length === 0 &&
                            <Alert severity="error">
                                Ocorreu um erro ao carregar a lista de projetos.
                                Por favor tente novamente.
                            </Alert>
                        }
                        { !loading && projects.length > 0 &&
                            <DataGrid
                                rows={projects}
                                columns={columns}
                                initialState={{ pagination: { paginationModel: { pageSize: 10 }} }}
                                pageSizeOptions={[10]}
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={setSelectedRows}
                                onRowClick={openSelectedProject} 
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-delete-dialog-title"
                aria-describedby="alert-delete-dialog-description"
            >
                <DialogTitle id="alert-delete-dialog-title">
                    {"Deseja excluir os projetos selecionados?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-delete-dialog-description">
                        Esse processo irá excluir permanentemente os projetos selecionados, e não
                        poderá ser desfeito após confirmado.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color='primary'>Cancelar</Button>
                    <Button onClick={handleDeleteProjects} autoFocus color='error'>
                        Excluir projetos
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

