import React from "react";
import { ButtonBase, Stack, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { logException } from "../../../components/ObservabilityProvider";

export default function UserProfileLoggedOutMenu() {
    const { instance } = useMsal();
    
    const handleClick = async (): Promise<void> => {
        await instance.loginRedirect(loginRequest).catch((error) => logException(error));
    };

    return (
        <ButtonBase sx={{ p: 0.25, bgcolor: 'transparent', borderRadius: 1, '&:hover': { bgcolor: '#424242' } }} onClick={handleClick}>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                <LoginIcon sx={{ width: 32, height: 32 }}/>
                <Typography variant="subtitle1">Entrar</Typography>
            </Stack>
        </ButtonBase>
    );
};
