import React from "react";
import { LogoutOutlined } from '@mui/icons-material';
import { Avatar, Box, ButtonBase, Card, CardContent, ClickAwayListener, Grid, IconButton, Paper, Popper, Stack, Typography } from "@mui/material";
import { alpha, useTheme } from '@mui/material/styles';
import Transitions from '../../../components/@extended/Transitions';
import ProfileTab from './ProfileTab';
import { useMsal } from '@azure/msal-react';
import AvatarGenericBlue from '../../../assets/images/avatar_generic_blue.png';

interface TabPanelProps {
    children: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`
    };
}

export default function UserProfileLoggedInMenu() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const { instance } = useMsal();

    // TODO
    const enableUserMenuOptions = true;

    const givenNameMemo = React.useMemo(() => {
        const account = instance.getActiveAccount();

        if (account && account.idTokenClaims) {
            const displayName = account.idTokenClaims['display_name'] as string;
            const givenName = account.idTokenClaims['given_name'] as string;

            if (displayName !== null
                && displayName !== undefined) {
                return displayName;
            }

            if (givenName !== null
                && givenName !== undefined) {
                return givenName;
            }
        }

        return 'Usuário';
    }, [instance]);

    const theme = useTheme();

    const anchorRef = React.useRef(null);
    const iconBackColorOpen = '#424242';
    const shadowZ1 = `0px 2px 8px ${alpha(theme.palette.grey[900], 0.15)}`;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const handleClose = (event: any) => {
        const ref = anchorRef as any;
        if (ref.current && ref.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleLogout = (): Promise<void> => instance.logout();

    return (
        <>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: iconBackColorOpen }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar alt="profile user" src={AvatarGenericBlue} sx={{ width: 32, height: 32 }} />
                    <Typography variant="subtitle1">{givenNameMemo}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: shadowZ1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>

                                    <Card elevation={0} role='presentation' sx={{
                                        border: 'none',
                                        borderRadius: 2,
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A700,
                                        boxShadow: 'inherit',
                                        ':hover': {
                                            boxShadow: 'inherit'
                                        },
                                        '& pre': {
                                            m: 0,
                                            p: '16px !important',
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: '0.75rem'
                                        }
                                    }}>

                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Stack direction="row" spacing={1.25} alignItems="center">
                                                        <Avatar alt="profile user" src={AvatarGenericBlue} sx={{ width: 32, height: 32 }} />
                                                        <Stack>
                                                            <Typography variant="h6">{givenNameMemo}</Typography>
                                                            <Typography variant="body2" color="textSecondary">
                                                                Usuário conectado
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton size="large" color="secondary" onClick={handleLogout}>
                                                        <LogoutOutlined />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        {open && enableUserMenuOptions && (
                                            <>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />
                                                <ProfileTab closeMenu={() => setOpen(false)} />
                                            </>
                                        )}
                                    </Card>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </>
    );
};
