import * as React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { NewRelicOptionsDev, NewRelicOptionsProd } from '../newRelicConfic';

const isLessThanWarning = (level: Sentry.SeverityLevel | undefined): boolean => {
	return level === 'log' || level === 'info' || level === 'debug';
}

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	tracesSampleRate: 1.0, //  Capture 100% of the transactions,
	integrations: [Sentry.browserTracingIntegration()],
	tracePropagationTargets: [process.env.REACT_APP_API_BASEURL as string],
	release: process.env.REACT_APP_SENTRY_RELEASE,
	beforeSend(event, hint) {
		return process.env.REACT_APP_SENTRY_LOGALL === 'false' && isLessThanWarning(event.level)
			? null
			: event;
	}
});

let newRelicAgent: BrowserAgent | null = null;

if (process.env.REACT_APP_NEWRELIC_ENVIRONMENT === 'development') {
	newRelicAgent = new BrowserAgent(NewRelicOptionsDev);
} else if (process.env.REACT_APP_NEWRELIC_ENVIRONMENT === 'production') {
	newRelicAgent = new BrowserAgent(NewRelicOptionsProd);
}

export function setUser(username: string | null): void {
    if (username !== null && username !== undefined) {
        Sentry.setUser({
            id: username,
            ip_address: '{{auto}}'
        });
    } else {
        Sentry.setUser({
            ip_address: '{{auto}}'
        });
    }

    if (newRelicAgent !== null) {
        newRelicAgent.setUserId(username ?? null);
    }
}

export function logDebug(message: string): void {
    Sentry.captureMessage(message, "debug");
}

export function logError(message: string): void {
    Sentry.captureException(message);
}

export function logInfo(message: string): void {
    Sentry.captureMessage(message, 'info');
}

export function logTrace(message: string): void {
    Sentry.captureMessage(message, 'log');
}

export function logWarning(message: string): void {
    Sentry.captureMessage(message, 'warning');
}

export function logException(exception: any): void {
    Sentry.captureException(exception);
}

export default function ObservabilityProvider(props: { children: JSX.Element }) {
    return ( <> { props.children } </> );
};
