import * as React from 'react'
import * as ServiceFactory from '../../services/ServiceFactory';
import { useMsal } from '@azure/msal-react';
import { Alert, Button, CircularProgress, Container, Divider, Fade, Grid, LinearProgress, Paper, Slide, SlideProps, Snackbar, TextField, Typography } from '@mui/material';
import { DefaultUserResponse, IUserResponse } from '../../services/models/IUserResponse';
import { green } from '@mui/material/colors';
import BadgeIcon from '@mui/icons-material/Badge';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { TransitionProps } from '@mui/material/transitions';

const alertDurationMs = 3000;

const isFieldEmpty = (reference?: string): boolean => {
    return reference === null
        || reference === undefined
        || reference.trim().length === 0;
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
};

const sanitizeResponse = (data: IUserResponse): IUserResponse => {
    return {
        username: data.username,
        roles: data.roles,
        permissions: data.permissions,
        displayName: data.displayName ?? '',
        givenName: data.givenName ?? '',
        surname: data.surname ?? '',
        jobTitle: data.jobTitle ?? '',
        companyName: data.companyName ?? '',
        cpf: data.cpf ?? '',
        cnpj: data.cnpj ?? ''
    };
};

export default function UserProfile() {
    const [ isLoading, setIsLoading ] = React.useState(true);
    const [ isSaving, setIsSaving ] = React.useState(false);
    const [ userProfile, setUserProfile ] = React.useState<IUserResponse>(DefaultUserResponse);
    const [ displayNameError, setDisplayNameError ] = React.useState(isFieldEmpty(userProfile.displayName));
    const [ givenNameError, setGivenNameError ] = React.useState(isFieldEmpty(userProfile.givenName));
    const [ surnameError, setSurnameError ] = React.useState(isFieldEmpty(userProfile.surname));
    const [ hasInvalidInput, setHasInvalidInput ] = React.useState(false);
    const { instance } = useMsal();

    const [ successSnackbarState, setSuccessSnackbarState ] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>;
    }>({
        open: false,
        Transition: Fade,
    });

    const [ failureSnackbarState, setFailureSnackbarState ] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>;
    }>({
        open: false,
        Transition: Fade,
    });

    const userService = ServiceFactory.CreateUserService(instance);

    React.useEffect(() => {
        loadProfile();
    }, []);

    React.useEffect(() => {
        setDisplayNameError(isFieldEmpty(userProfile.displayName));
        setGivenNameError(isFieldEmpty(userProfile.givenName));
        setSurnameError(isFieldEmpty(userProfile.surname));
    }, [userProfile]);

    React.useEffect(() => {
        setHasInvalidInput(displayNameError || givenNameError || surnameError);
    }, [displayNameError, givenNameError, surnameError]);

    const saveProfileButtonColor = React.useMemo(() => {
        if (hasInvalidInput) {
            return 'warning';
        }

        return 'primary';
    }, [hasInvalidInput]);

    const loadProfile = (): Promise<void> => {
        setIsLoading(true);

        return userService.get(true, (response) => {
            if (response !== null && response !== undefined) {
                const sanitized = sanitizeResponse(response);
                setUserProfile(sanitized);
            }

            setIsLoading(false);
        })
    };

    const onSave = async (): Promise<void> => {
        if (isSaving || hasInvalidInput) {
            return;
        }

        setIsSaving(true);

        userService.put(userProfile, (success) => {
            if (success) {
                showSuccessSnackbar(SlideTransition);
            } else {
                showFailureSnackbar(SlideTransition);
            }

            setIsSaving(false);
        });
    }

    const showSuccessSnackbar =
    (
      Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>
    ): void => {
        setSuccessSnackbarState({open: true, Transition});
    };

    const showFailureSnackbar =
    (
      Transition: React.ComponentType<TransitionProps & { children: React.ReactElement<any, any>; }>
    ): void => {
        setFailureSnackbarState({open: true, Transition});
    };

    const onSuccessSnackbarClose = (): void => {
        setSuccessSnackbarState({ ...successSnackbarState, open: false });
    };

    const onFailureSnackbarClose = (): void => {
        setFailureSnackbarState({ ...failureSnackbarState, open: false });
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='lg'>
            <Grid container spacing={1} paddingBottom={2} >
                <Grid item xs='auto'>
                    <BadgeIcon fontSize='large' />
                </Grid>
                <Grid item xs='auto'>
                    <Typography component="h2" variant="h4">
                        Perfil de usuário
                    </Typography>

                </Grid>


            </Grid>

            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ pl: '24px', pt: '6px' }} variant="h6">
                    Dados pessoais
                </Typography>
                <Divider sx={{ pl: '24px', pr: '24px' }} />

                { isLoading
                    ? <Grid container sx={{ pt: 5, pb: 5, justifyContent: 'center' }}>
                        <LinearProgress sx={{ width: '85%' }} />
                    </Grid>
                    : <Container component="form" sx={{ paddingTop: 2, paddingBottom: 3 }} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField variant='standard' type='string'
                                    id='userProfile-displayName-textField'
                                    label='Nome para exibição'
                                    required
                                    helperText='Exemplo: "Ana"'
                                    value={userProfile.displayName}
                                    onChange={(event) => setUserProfile({ ...userProfile, displayName: event.target.value as string })}
                                    error={displayNameError}
                                />
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: '1px' }} spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-givenName-textField'
                                    label='Nome'
                                    required
                                    helperText='Exemplo: "Ana Paula"'
                                    value={userProfile.givenName}
                                    onChange={(event) => setUserProfile({ ...userProfile, givenName: event.target.value as string })}
                                    error={givenNameError}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-surname-textField'
                                    label='Sobrenome'
                                    required
                                    helperText='Exemplo: "de Oliveira"'
                                    value={userProfile.surname}
                                    onChange={(event) => setUserProfile({ ...userProfile, surname: event.target.value as string })}
                                    error={surnameError}
                                />
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: '1px' }} spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-cpf-textField'
                                    label='CPF'
                                    helperText='Formato: 000.000.000/00'
                                    value={userProfile.cpf}
                                    onChange={(event) => setUserProfile({ ...userProfile, cpf: event.target.value as string })}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                }

                <Typography sx={{ pl: '24px', pt: '24px' }} variant="h6">
                    Dados profissionais
                </Typography>
                <Divider sx={{ pl: '24px', pr: '24px' }} />

                { isLoading
                    ? <Grid container sx={{ pt: 5, pb: 5, justifyContent: 'center' }}>
                        <LinearProgress sx={{ width: '85%' }} />
                    </Grid>
                    : <Container component="form" sx={{ paddingTop: 2, paddingBottom: 3 }} noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-companyName-textField'
                                    label='Nome da empresa'
                                    value={userProfile.companyName}
                                    onChange={(event) => setUserProfile({ ...userProfile, companyName: event.target.value as string })}
                                />
                            </Grid>
                        </Grid>

                        {/* <Grid container sx={{ mt: '1px' }} spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-cnpj-textField'
                                    label='CNPJ'
                                    helperText='Formato: 00.000.000/0001-00'
                                    value={userProfile.cnpj}
                                    onChange={(event) => setUserProfile({ ...userProfile, cnpj: event.target.value as string })}
                                />
                            </Grid>
                        </Grid> */}

                        <Grid container sx={{ mt: '1px' }} spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth variant='standard' type='string'
                                    id='userProfile-jobTitle-textField'
                                    label='Cargo'
                                    value={userProfile.jobTitle}
                                    onChange={(event) => setUserProfile({ ...userProfile, jobTitle: event.target.value as string })}
                                />
                            </Grid>
                        </Grid>

                    </Container>
                }

                { !isLoading && 
                    <Container component='form' sx={{ paddingTop: 4, paddingBottom: 3 }} noValidate>
                        <Grid container spacing={1} sx={{display:'flex', alignItems:'center'}} >
                            <Grid item xs='auto' sx={{position: 'relative'}}>
                                <Button variant="contained" color={saveProfileButtonColor} startIcon={<SaveAltIcon />} disabled={isSaving} onClick={onSave}>
                                    Salvar
                                </Button>
                                {isSaving && (
                                    <CircularProgress size={24} sx={{color:green[600], position:'absolute', top:'30%', left:'50%', marginLeft:'-6px'}} />
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                }
            </Paper>

            <Snackbar open={successSnackbarState.open} onClose={onSuccessSnackbarClose} TransitionComponent={successSnackbarState.Transition} autoHideDuration={alertDurationMs}>
                <Alert onClose={onSuccessSnackbarClose} severity='success' variant='filled' sx={{ width: '100%' }}>
                    Perfil de usuário salvo!
                </Alert>
            </Snackbar>

            <Snackbar open={failureSnackbarState.open} onClose={onFailureSnackbarClose} TransitionComponent={failureSnackbarState.Transition} autoHideDuration={alertDurationMs}>
                <Alert onClose={onFailureSnackbarClose} severity='error' variant='filled' sx={{ width: '100%' }}>
                    Ocorreu um erro ao tentar salvar o perfil de usuário!
                </Alert>
            </Snackbar>
            
        </Container>
    );
}
