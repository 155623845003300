export const EmptyInputId: string = '00000000-0000-0000-0000-000000000000';

export interface IOilConditionData {
    id: number; // UI state only, does not need to be persisted
    name: string;
    avgTempRise: number;
    topOilTempRise: number;
    loadLosses: number;
    noLoadLosses: number;
    coolingMode: number; // TODO - from enum
};

export interface ILoadPeriod {
    id: number; // UI state only, does not need to be persisted
    index: number;
    load: number;
    duration: number;
    ambientTemperature: number;
    selected: boolean; // UI state only, does not need to be persisted
};

export interface IWindingConditionData {
    id: number; // UI state only, does not need to be persisted
    name: string;
    gradient: number;
    loadLosses: number;
};

export interface Winding {
    id: number; // UI state only, does not need to be persisted
    name: string;
    mass: number;
    conductorType: number; // TODO - from enum
    hotSpotFactor: number;
    conditions: Array<IWindingConditionData>;
    selected: boolean; // UI state only, does not need to be persisted
};

export interface ITransLifespanInputData {
    transformerPowerkVA: number;
    insulatingSystem: number; // TODO - from enum
    oilConditionName: string;
    coreMass: number;
    coilsMass: number;
    oilMass: number;
    oilMassSpecification: number; // TODO - from enum
    tankAndFittingsMass: number;
    oilConditions: Array<IOilConditionData>;
    timeInterval: number;
    initialLoad: number;
    initialAmbientTemperature: number;
    loadPeriods: Array<ILoadPeriod>;
    windings: Array<Winding>;
};

export interface ITransLifespanCalculationRequest {
    id: string;
    saveResult: boolean;
    reference: string;
    inputData: ITransLifespanInputData;
};

export interface ITransLifespanNewProjectRequest {
    reference: string;
    inputData: ITransLifespanInputData;
}

export const DefaultTransLifespanCalculationRequest: ITransLifespanCalculationRequest = {
    id: EmptyInputId,
    saveResult: false,
    reference: '',
    inputData: {
        transformerPowerkVA: 0,
        insulatingSystem: 5,
        oilConditionName: 'new',
        coreMass: 0,
        coilsMass: 0,
        oilMass: 0,
        oilMassSpecification: 0,
        tankAndFittingsMass: 0,
        oilConditions: [{
            id: 0,
            avgTempRise: 99,
            loadLosses: 9999,
            name: 'new',
            topOilTempRise: 99,
            noLoadLosses: 0,
            coolingMode: 0
        }],
        timeInterval: 1,
        initialLoad: 0,
        initialAmbientTemperature: 0,
        loadPeriods: [{
            id: 0,
            index: 0,
            load: 1,
            duration: 480,
            ambientTemperature: 30,
            selected: false
        }],
        windings: [{
            id: 0,
            conductorType: 1,
            name: 'wdg1',
            hotSpotFactor: 0,
            mass: 0,
            conditions: [{
                id: 0,
                name: 'new',
                gradient: 99,
                loadLosses: 9999
            }],
            selected: true
        },
        {
            id: 1,
            conductorType: 1,
            name: 'wdg2',
            hotSpotFactor: 0,
            mass: 0,
            conditions: [{
                id: 0,
                name: 'new',
                gradient: 98,
                loadLosses: 9998
            }],
            selected: false
        }]
    }
};
