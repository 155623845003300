import React from 'react'
import { Box, Container, Grid, Link, Paper, Typography } from '@mui/material';
import { LinkedIn as LinkedInIcon, MailOutline as MailOutlineIcon, WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import ContactQR from '../assets/images/Codigo-QR-150x150.png';

export default function TransLifespanMain() {
    return (
        <Container maxWidth="md">
            <Paper sx={{
                backgroundColor: 'white',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginTop: 4}}>

                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{pt:{xs:3, md:6}, pb:2, pl:{xs:3, md:6}, pr:{xs:3, md:6}}} >
                            <Typography component="h2" variant="h5" gutterBottom>
                                Contato
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                Entre em contato conosco! <br />
                                Envie suas sugestões, dúvidas técnicas ou necessidades e lhe responderemos o mais breve possível
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item md={12}>
                        <Box sx={{pt:2, pb:2, pl:{xs:3, md:6}, pr:{xs:3, md:6}}} >
                            <Typography component="h3" variant="h6" gutterBottom>
                                Para falar conosco
                            </Typography>
                            <Box component='img' src={ContactQR} sx={{maxWidth: '100%'}}></Box>
                        </Box>
                    </Grid>

                    <Grid item md={12}>
                        <Box sx={{pt:2, pb:2, pl:{xs:3, md:6}, pr:{xs:3, md:6}}} >
                            <Typography component="h3" variant="h6" gutterBottom>
                                Para nos enviar uma mensagem
                            </Typography>
                            <Link rel="noopener noreferrer" target="_blank" color="inherit" href='mailto:contato@trinse.net'>
                                <MailOutlineIcon fontSize='large' sx={{display:'inline', marginRight:2}} />
                            </Link>
                            <Link rel="noopener noreferrer" target="_blank" color="inherit" href='https://api.whatsapp.com/send?phone=5547991189489&text=Ol%C3%A1.%20Entro%20em%20contato%20pelo%20Site%20da%20TRINSE.'>
                                <WhatsAppIcon fontSize='large' sx={{display:'inline', marginRight:2, color:'#25D366'}}/>
                            </Link>
                            <Link rel="noopener noreferrer" target="_blank" color="inherit" href='https://www.linkedin.com/company/trinse/'>
                                <LinkedInIcon fontSize='large' sx={{color:'#0A66C2'}} />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>

            </Paper>
        </Container>
    );
}