import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import TransLifespanSteps from './TransLifespanSteps';
import { Grid, LinearProgress, StepLabel } from '@mui/material';

export interface ITransLifespanStepperProps {
    projectLoading: boolean;
};

const steps = ['Dados básicos', 'Dados construtivos', 'Enrolamentos', 'Carregamento', 'Condições', 'Calcular vida útil'];

export default function TransLifespanStepper(props: ITransLifespanStepperProps) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed] = React.useState<{
        [k: number]: boolean;
    }>({});
    const [stepError, setStepError] = React.useState<{
        [k: number]: boolean;
    }>({});

    const setErrorState = (step: number, isErrorState: boolean): void => {
        const errors = stepError;
        errors[step] = isErrorState;
        setStepError({...errors});
    };

    const isInputValid = React.useMemo(() => {
        let result = true;

        for (let key in stepError) {
            result &&= !stepError[key];
        }

        return result;
    }, [stepError]);

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ width: '100%', minHeight: 500, position: 'relative', height: 'auto' }}>
            <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            <StepLabel error={stepError[index]}>{label}</StepLabel>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {props.projectLoading
                ? <Grid container sx={{ pt:5, justifyContent:'center'}}>
                    <LinearProgress sx={{width:'85%'}} />
                </Grid>
                : <Box sx={{ height: '100%' }}>
                    <TransLifespanSteps step={activeStep} setErrorState={setErrorState} isInputValid={isInputValid} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, position: 'absolute', bottom: 0, width: '100%' }}>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>Anterior</Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button disabled={isLastStep()} onClick={handleNext} sx={{ mr: 1 }}>Seguinte</Button>
                    </Box>
                </Box>
            }
        </Box>
    );
}