import * as React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { CalcResponseContext } from '../TransformerLifespanContext';
import * as Branding from './TransLifespanBranding';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ArredMultB = (value: number, nearValue: number): number => {
    const rest = value % nearValue;
    return value - rest;
};

const GetTicks = (maxLifeConsumed: number): number => {
    const decimals = Math.pow(10, Math.floor(Math.min(20 / maxLifeConsumed, 15)));
    return ArredMultB(maxLifeConsumed / 8, Math.round((maxLifeConsumed / 100) * decimals) / decimals);
};

export default function TransLifespanConsumedGraph() {
    const { calcResponse } = React.useContext(CalcResponseContext);

    const graphData = React.useMemo(() => {
        const windingDatasets = calcResponse.graphOutput.windingLifeConsumedGraphOutput.map((w, index) => ({
            backgroundColor: Branding.GetGraphColor(index + 1),
            borderColor: Branding.GetGraphColor(index + 1),
            data: w.graphCurveOutput,
            label: w.name,
            showLine: true,
            pointRadius: 0
        }));

        return {
            datasets: [...windingDatasets]
        };
    }, [calcResponse]);

    const maxLifeConsumed = React.useMemo(() => 
        graphData.datasets.flatMap(d => d.data).reduce((previous, current) => (previous && previous.y > current.y) ? previous : current).y
    , [calcResponse])

    const graphOptions = React.useMemo(() => ({
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Tempo de vida consumido',
            }
        },
        responsive: true,
        scales: {
            x: {
                min: 0,
                max: calcResponse.graphOutput.topOilTemperature[calcResponse.graphOutput.topOilTemperature.length - 1].x,
                title: {
                    display: true,
                    text: 'Tempo [min]'
                }
            },
            y: {
                min: 0,
                max: maxLifeConsumed * 1.1,
                title: {
                    display: true,
                    text: 'Tempo consumido'
                },
                ticks: {
                    stepSize: GetTicks(maxLifeConsumed),
                    // callback: function (val: any, index: any) {
                    //     return Number(val).toFixed(5);
                    // }
                }
            }
        }
    }), [calcResponse]);

    return (
        <Scatter options={graphOptions} data={graphData} />
    );
}
