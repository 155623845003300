import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from '../../../authConfig';
import { useNavigate } from 'react-router-dom';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';

export interface IProfileTabProps {
    closeMenu: () => void;
};

export default function ProfileTab(props: IProfileTabProps) {
    const { instance, inProgress } = useMsal();
    const theme = useTheme();
    const navigate = useNavigate();

    const handleUserProfileChange = (): void => {
        props.closeMenu();
        navigate("/userprofile");
    };

    const handleChangePassword = (): void => {
        props.closeMenu();

        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
                ...b2cPolicies.authorities.forgotPassword,
                scopes: protectedResources.b2c.scopes.no_op
            });
        }
    };

    const handleLogout = (): Promise<void> => {
        props.closeMenu();
        return instance.logout();
    };

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: 'grey' } }}>
            <ListItemButton onClick={handleUserProfileChange}>
                <ListItemIcon>
                    <BadgeIcon />
                </ListItemIcon>
                <ListItemText primary="Editar perfil" />
            </ListItemButton>
            <ListItemButton onClick={handleChangePassword}>
                <ListItemIcon>
                    <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary="Trocar senha" />
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sair" />
            </ListItemButton>
        </List>
    );
};
