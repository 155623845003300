import * as React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { CalcResponseContext } from '../TransformerLifespanContext';
import * as Branding from './TransLifespanBranding';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Next10th = (value: number): number => {
    const floor = Math.floor(value);
    return floor % 10 == 0 ? floor : floor + (10 - floor % 10);
};

export default function TransLifespanTempGraph() {
    const { calcResponse } = React.useContext(CalcResponseContext);

    const graphData = React.useMemo(() => {
        const topOilDataset = {
            backgroundColor: Branding.GetGraphColor(0),
            borderColor: Branding.GetGraphColor(0),
            data: calcResponse.outputData.graphOutput.topOilTemperature,
            label: 'Topo do óleo',
            showLine: true,
            pointRadius: 0
        }

        const windingDatasets = calcResponse.outputData.graphOutput.windingTemperatureGraphOutput.map((w, index) => ({
            backgroundColor: Branding.GetGraphColor(index + 1),
            borderColor: Branding.GetGraphColor(index + 1),
            data: w.graphCurveOutput,
            label: w.name,
            showLine: true,
            pointRadius: 0
        }));

        return {
            datasets: [topOilDataset, ...windingDatasets]
        };
    }, [calcResponse])

    const maxTemp = React.useMemo(() => 
        graphData.datasets.flatMap(d => d.data).reduce((previous, current) => (previous && previous.y > current.y) ? previous : current).y
    , [calcResponse]);

    const graphOptions = React.useMemo(() => ({
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: true,
                text: 'Temperaturas',
            }
        },
        responsive: true,
        scales: {
            x: {
                min: 0,
                max: calcResponse.outputData.graphOutput.topOilTemperature[calcResponse.outputData.graphOutput.topOilTemperature.length - 1].x,
                title: {
                    display: true,
                    text: 'Tempo [min]'
                }
            },
            y: {
                min: 0,
                max: Next10th(maxTemp * 1.1),
                title: {
                    display: true,
                    text: 'Temperatura [°C]'
                }
            }
        }
    }), [calcResponse]);

    return (
        <Scatter options={graphOptions} data={graphData} />
    );
}
