import * as React from 'react';
import { Box } from '@mui/material';
import TransLifespanOilStep from './TransLifespanOilStep';
import TransLifespanBasicDataStep from './TransLifespanBasicDataStep';
import TransLifespanWindingStep from './TransLifespanWindingStep';
import TransLifespanLoadStep from './TransLifespanLoadStep';
import TransLifespanConditionStep from './TransLifespanConditionStep';
import TransLifespanCalculationStep from './TransLifespanCalculationStep';

export interface ITransLifespanSteps {
    step: number;
    setErrorState: (step: number, isErrorState: boolean) => void;
    isInputValid: boolean;
};

export default function TransLifespanSteps(props: ITransLifespanSteps) {
    const [stepError, setStepError] = React.useState<{
        [k: number]: boolean;
    }>({});

    const setErrorState = (isErrorState: boolean): void => {
        const errors = stepError;
        errors[props.step] = isErrorState;
        setStepError({...errors});

        props.setErrorState(props.step, isErrorState);
    };

    return (
        <Box sx={{ marginBottom: 4 }}>
            { props.step === 0 ? <TransLifespanBasicDataStep setErrorState={setErrorState} /> : <></>}
            { props.step === 1 ? <TransLifespanOilStep setErrorState={setErrorState} /> : <></>}
            { props.step === 2 ? <TransLifespanWindingStep /> : <></>}
            { props.step === 3 ? <TransLifespanLoadStep /> : <></>}
            { props.step === 4 ? <TransLifespanConditionStep /> : <></>}
            { props.step === 5 ? <TransLifespanCalculationStep isInputValid={props.isInputValid} /> : <></>}
        </Box>
    );
}