import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import * as ServiceFactory from './services/ServiceFactory';
import ObservabilityProvider from './components/ObservabilityProvider';

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const healthcheckService = ServiceFactory.CreateHealthcheckService();
healthcheckService.getSilent();

const container = document.getElementById('app-root')!;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<ObservabilityProvider>
			<BrowserRouter>
				<App {...{ msal: msalInstance }} />
			</BrowserRouter>
		</ObservabilityProvider>
	</React.StrictMode>
);

// TODO add to info
// <a href="https://www.flaticon.com/free-icons/avatar" title="avatar icons">Avatar icons created by riajulislam - Flaticon</a>
