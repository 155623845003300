import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TransLifespanStepper from './TransLifespanStepper';
import { DefaultTransLifespanCalculationResponse, ITransLifespanCalculationResponse } from './ITransLifespanCalculationResponse';
import { DefaultTransLifespanCalculationRequest, ITransLifespanCalculationRequest } from './ITransLifespanCalculationRequest';
import { CalcRequestContext, CalcResponseContext } from './TransformerLifespanContext';
import * as ServiceFactory from '../../services/ServiceFactory';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { ITransLifespanCalculationProject } from './ITransLifespanCalculationProject';
import { logWarning } from '../../components/ObservabilityProvider';

export default function TransLifespanProjectEdit() {
    const [calcRequest, setCalcRequest] = React.useState<ITransLifespanCalculationRequest>(DefaultTransLifespanCalculationRequest);
    const [calcResponse, setCalcResponse] = React.useState<ITransLifespanCalculationResponse>(DefaultTransLifespanCalculationResponse);
    const [projectLoading, setProjectLoading] = React.useState(true);
    const { projectId } = useParams();
    const { instance } = useMsal();

    const transLifespanService = ServiceFactory.CreateTransLifespanService(instance);

    const calcRequestValue = React.useMemo(
        () => ({ calcRequest, setCalcRequest }),
        [calcRequest]
    );

    const calcResponseValue = React.useMemo(
        () => ({ calcResponse, setCalcResponse }),
        [calcResponse]
    );

    React.useEffect(() => {
        setProjectLoading(true);

        if (projectId === undefined || projectId === 'new') {
            setProjectLoading(false);
            return;
        }
        
        transLifespanService.getProject(projectId, (response) => {
            if (response === null || response === undefined) {
                logWarning('Project response body is empty.');
                setProjectLoading(false);
                return;
            }

            response.inputData.windings[0].selected = true;
            const sanitized = sanitizeInput(response);
            setCalcRequest(sanitized);
            setProjectLoading(false);
        });
    }, [projectId]);

    const sanitizeInput = (data: ITransLifespanCalculationProject): ITransLifespanCalculationRequest => {
        const response = data;
        
        response.inputData.oilConditions.forEach((oilCondition, index) => {
            oilCondition.id = index;
        });

        response.inputData.windings.forEach((winding, windingIndex) => {
            winding.id = windingIndex;
            
            winding.conditions.forEach((windingCondition) => {
                var oilCondition = response.inputData.oilConditions.find(o => o.name === windingCondition.name);
                windingCondition.id = oilCondition!.id;
            })
        });

        response.inputData.loadPeriods.forEach(load => load.id = load.index);

        return response;
    }

    return (
        <CalcRequestContext.Provider value={calcRequestValue}>
            <CalcResponseContext.Provider value={calcResponseValue}>
                <Container sx={{ mt: 4, mb: 4 }}>
                    <Grid>
                    {/* <Grid spacing={3}> */}
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                            }}
                        >
                            <TransLifespanStepper projectLoading={projectLoading} />
                        </Paper>
                        </Grid>
                    </Grid>
                    {/* <Copyright sx={{ pt: 4 }} /> */}
                </Container>
            </CalcResponseContext.Provider>
        </CalcRequestContext.Provider>
    );
}
