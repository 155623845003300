export interface ITransLifespanWindingsOutput {
    id: number; // UI state only, does not need to be persisted
    name: string;
    hotspotTemperature: number;
    hotspotTime: number;
    lifetimeConsumed: number;
};

export interface IGraphPointOutput {
    x: number;
    y: number;
}

export interface ITransLifespanNamedCurveGraphOutput {
    name: string;
    graphCurveOutput: Array<IGraphPointOutput>;
}

export interface ITransLifespanGraphOutput {
    topOilTemperature: Array<IGraphPointOutput>;
    windingTemperatureGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
    windingLifespanGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
    windingLifeConsumedGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
}

export interface ITransLifespanOutputData {
    calculated: boolean;
    lifetimeConsumedMin: number;
    lifetimeConsumedDay: number;
    agingRate: number;
    expectedLifespan: number;
    maximumOilTemperature: number;
    windingsOutput: Array<ITransLifespanWindingsOutput>;
    warnings: string;
    graphOutput: ITransLifespanGraphOutput
};

export interface ITransLifespanCalculationResponse {
    outputData: ITransLifespanOutputData;
};

export const DefaultTransLifespanCalculationResponse: ITransLifespanCalculationResponse = {
    outputData: {
        calculated: false,
        lifetimeConsumedMin: 0,
        lifetimeConsumedDay: 0,
        agingRate: 0,
        expectedLifespan: 0,
        maximumOilTemperature: 0,
        windingsOutput: [],
        warnings: "",
        graphOutput: {
            topOilTemperature: [],
            windingTemperatureGraphOutput: [],
            windingLifespanGraphOutput: [],
            windingLifeConsumedGraphOutput: []
        }
    }
};