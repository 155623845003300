import * as React from 'react';
import { Button, Container, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CalcRequestContext } from './TransformerLifespanContext';
import { Winding } from './ITransLifespanCalculationRequest';

export default function TransLifespanWindingStep() {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const [ canDelete, setCanDelete ] = React.useState(false);
    
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Nome', type: 'string', width: 240, editable: true },
        { field: 'mass', headerName: 'Massa (kg)', type: 'number', width: 150, editable: true },
        { field: 'conductorType', headerName: 'Tipo de condutor', type: 'singleSelect', width: 150, editable: true,
            valueOptions: [{value: 1, label: 'Alumínio'}, {value: 0, label: 'Cobre'}]
        },
        { field: 'hotSpotFactor', headerName: 'Fator hot-spot', type: 'number', width: 150, editable: true },
        { field: 'delete', headerName: '', align: 'right', type: 'actions',
            valueGetter: () => calcRequest.inputData.windings.length <= 1,
            renderCell: params => (params.api.getCellValue(params.row.id, 'delete') as boolean) ? <></> : <Button variant="text" endIcon={<DeleteOutlineIcon />} onClick={() => removeRowById(params.row.id)}></Button>
        }
    ];

    const removeRowById = (rowId: number): void => {
        const newState = calcRequest.inputData;
        const rowPosition = newState.windings.findIndex(w => w.id === rowId);
        newState.windings = [...newState.windings];
        newState.windings.splice(rowPosition, 1);

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const addWinding = (): void => {
        const newState = calcRequest.inputData;
        const maxId = Math.max(...newState.windings.map(w => w.id)) + 1;
        const newName = 'wdg' + maxId;

        const newWinding: Winding = {
            id: maxId,
            name: newName,
            selected: false,
            mass: 99,
            hotSpotFactor: 999,
            conductorType: 1,
            conditions: newState.oilConditions.map(function (oilCondition) {
                return {
                    id: oilCondition.id,
                    name: oilCondition.name,
                    gradient: 99,
                    loadLosses: 9999
                };
            })
        };

        newState.windings = [...newState.windings, newWinding];
        setCalcRequest({...calcRequest, inputData: newState});
    };

    const removeSelectedWindings = (): void => {
        const newState = calcRequest.inputData;
        newState.windings = newState.windings.filter(w => !w.selected);

        if (newState.windings.length === 0) {
            newState.windings = [{
                id: 0,
                name: 'Wdg1',
                selected: false,
                mass: 99,
                hotSpotFactor: 999,
                conductorType: 1,
                conditions: newState.oilConditions.map(function (oilCondition) {
                    return {
                        id: oilCondition.id,
                        name: oilCondition.name,
                        gradient: 99,
                        loadLosses: 9999
                    };
                })
            }];
        }

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const setSelectedRows = (evt: GridRowSelectionModel): void => {
        const newState = calcRequest.inputData;

        newState.windings.forEach(w => {
            w.selected = evt.includes(w.id);
        });

        setCanDelete(evt.length > 0)
        setCalcRequest({...calcRequest, inputData: newState});
    };

    const windingProcessRowUpdate = (newRow: GridRowModel): GridRowModel => {
        const newState = calcRequest.inputData;

        const winding = newState.windings.find(w => w.id === newRow.id);

        if (winding) {
            winding.name = newRow.name;
            winding.mass = newRow.mass;
            winding.conductorType = newRow.conductorType;
            winding.hotSpotFactor = newRow.hotSpotFactor;
        }

        setCalcRequest({...calcRequest, inputData: newState});

        return newRow;
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }} maxWidth='md'>
            <Grid container spacing={1} paddingBottom={3} >
                <Grid item xs='auto'>
                    <Button variant="contained" startIcon={<AddIcon />} onClick={addWinding}>Acrescentar</Button>
                </Grid>
                <Grid item xs='auto'>
                    <Button variant="contained" disabled={!canDelete} startIcon={<DeleteIcon />} onClick={removeSelectedWindings}>Remover</Button>
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent='center'>
                <Grid sx={{ height: 300}} item xs>
                    <DataGrid
                        rows={calcRequest.inputData.windings}
                        columns={columns}
                        initialState={{ pagination: { paginationModel: { pageSize: 5 }} }}
                        isRowSelectable={() => calcRequest.inputData.windings.length > 1}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={setSelectedRows}
                        processRowUpdate={windingProcessRowUpdate}
                         />
                </Grid>
            </Grid>
        </Container>
    );
}