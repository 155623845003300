import Grid from '@mui/material/Grid';
import * as React from 'react'

export interface IPaletteColorDescription {
    colorPosition: number
    colorDetails: {[color: string] : {
        color: string,
        foreground: string,
        primary: boolean
    }}
};

export default function PaletteColorDescription(props: IPaletteColorDescription) {
    return (
        <Grid item sx={{
            marginLeft: 1,
            backgroundColor: props.colorDetails[props.colorPosition].color,
            color: props.colorDetails[props.colorPosition].foreground,
            width: '48px',
            height: '48px',
            lineHeight:'48px',
            textAlign:'center',
            verticalAlign:'middle',
            borderRadius: props.colorDetails[props.colorPosition].primary ? '48px' : '',
        }}>
            {props.colorPosition}
        </Grid>
    );
}