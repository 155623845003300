import * as React from 'react';
import * as ServiceFactory from '../services/ServiceFactory';
import { AccountInfo, AuthenticationResult, EventPayload, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies, protectedResources } from '../authConfig';
import { compareIssuingPolicy } from '../utils/ClaimUtils';
import { AbilityContext } from './Can';
import { Ability, AbilityBuilder, AbilityTuple, MongoQuery } from '@casl/ability';
import { IUserResponse } from '../services/models/IUserResponse';
import { setUser } from './ObservabilityProvider';

const isAccountInfo = (toBeDetermined: EventPayload): toBeDetermined is AccountInfo => {
    if ((toBeDetermined as AccountInfo).idTokenClaims) {
        return true;
    }

    return false;
};

const isAuthenticationResult = (toBeDetermined: EventPayload): toBeDetermined is AuthenticationResult => {
    if ((toBeDetermined as AuthenticationResult).account.idTokenClaims) {
        return true;
    }

    return false;
};

const updateUserAbility = (ability: Ability<AbilityTuple, MongoQuery>, user: void | IUserResponse) => {
    const { can, rules } = new AbilityBuilder(Ability);

    if (user !== null && user !== undefined) {
        let shouldViewAdminNav = false;

        user.permissions.forEach(function (permission) {
            switch (permission.toLowerCase()) {
                case 'update.user.self':
                    can('update', 'profile');
                    break;
                case 'view.branding':
                    can('view', 'branding');
                    shouldViewAdminNav = true;
                    break;
                case 'manage.user.all':
                    can('manage', 'users');
                    shouldViewAdminNav = true;
                    break;
                case 'view.devportal':
                    can('view', 'devportal');
                    break;
            }
        });

        if (shouldViewAdminNav) {
            can('view', 'adminnav')
        }
    }

    ability.update(rules);
};

export default function AuthenticationProvider(props: { children: JSX.Element }) {
    const [ username, setUsername ] = React.useState<string>('');
    const { instance } = useMsal();

    const userService = ServiceFactory.CreateUserService(instance);
    const ability = React.useContext(AbilityContext);

    React.useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            let activeAccount = instance.getActiveAccount();
            setUsername(activeAccount?.username ?? '');

            setUser(null);

            if (!activeAccount && instance.getAllAccounts().length > 0) {
                let accounts = instance.getAllAccounts();
                instance.setActiveAccount(accounts[0]);
            }

            if (event.eventType === EventType.LOGIN_SUCCESS
                && event
                && event.payload
                && isAuthenticationResult(event.payload)
            ) {
                const authenticationResult = event.payload as AuthenticationResult;
                const accountTokenClaims = authenticationResult.account.idTokenClaims!;
                const newUser = accountTokenClaims['newUser'] === true;

                userService.login(authenticationResult, () => {});
            }

            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
                && event
                && event.payload
                && isAccountInfo(event.payload)
            ) {
                const accountInfo = event.payload as AccountInfo;
                const accountTokenClaims = accountInfo.idTokenClaims!;

                setUser(accountTokenClaims['sub'] as string);

                if (compareIssuingPolicy(accountTokenClaims, b2cPolicies.names.editProfile)) {
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims!.oid === accountTokenClaims.oid &&
                                account.idTokenClaims!.sub === accountTokenClaims.sub &&
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.b2c.scopes.no_op,
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.message.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);

    React.useEffect(() => {
        if (!!username) {
            userService.get(false, (response) => { updateUserAbility(ability, response) });
        }
    }, [username]);

    return (
        <AbilityContext.Provider value={ability}>
            {props.children}
        </AbilityContext.Provider>
    );
};
