export interface ITransLifespanWindingsOutput {
    id: number; // UI state only, does not need to be persisted
    name: string;
    hotspotTemperature: number;
    hotspotTime: number;
    lifetimeConsumed: number;
};

export interface IGraphCoordinate {
    x: number;
    y: number;
}

export interface ITransLifespanNamedCurveGraphOutput {
    name: string;
    graphCurveOutput: Array<IGraphCoordinate>;
}

export interface ITransLifespanGraphOutput {
    topOilTemperature: Array<IGraphCoordinate>;
    windingTemperatureGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
    windingLifespanGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
    windingLifeConsumedGraphOutput: Array<ITransLifespanNamedCurveGraphOutput>;
}

export interface ITransLifespanOutputData {
    calculated: boolean;
    lifetimeConsumedMin: number;
    lifetimeConsumedDay: number;
    agingRate: number;
    expectedLifespan: number;
    maximumOilTemperature: number;
    windingsOutput: Array<ITransLifespanWindingsOutput>;
    warnings: string;
    graphOutput: ITransLifespanGraphOutput
};

export interface ITransLifespanCalculationResponse {
    outputData: ITransLifespanOutputData;
    resultSaved: boolean;
};

export const DefaultTransLifespanOutputData: ITransLifespanOutputData = {
    calculated: false,
    lifetimeConsumedMin: 0,
    lifetimeConsumedDay: 0,
    agingRate: 0,
    expectedLifespan: 0,
    maximumOilTemperature: 0,
    windingsOutput: [],
    warnings: "",
    graphOutput: {
        topOilTemperature: [],
        windingTemperatureGraphOutput: [],
        windingLifespanGraphOutput: [],
        windingLifeConsumedGraphOutput: []
    }
};
