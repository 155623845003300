import { Logging } from '@newrelic/browser-agent/features/logging'

export const NewRelicOptionsDev = {
    init: {
        distributed_tracing: {
            enabled:true
        },
        privacy: {
            cookies_enabled: true
        },
        ajax: {
            deny_list:["bam.eu01.nr-data.net"]
        }
    },
    info: {
        beacon: "bam.eu01.nr-data.net",
        errorBeacon: "bam.eu01.nr-data.net",
        licenseKey: "NRJS-ca102f8b56867404464",
        applicationID: "538616570",
        sa: 1
    },
    loader_config: {
        accountID: "4370424",
        trustKey: "4370424",
        agentID: "538616570",
        licenseKey: "NRJS-ca102f8b56867404464",
        applicationID: "538616570"
    },
    features: [
        Logging
    ]
};

export const NewRelicOptionsProd = {
    init: {
        distributed_tracing: {
            enabled: true
        },
        privacy: {
            cookies_enabled: true
        },
        ajax: {
            deny_list: ["bam.eu01.nr-data.net"]
        }
    },
    info: {
        beacon: "bam.eu01.nr-data.net",
        errorBeacon: "bam.eu01.nr-data.net",
        licenseKey: "NRJS-ca102f8b56867404464",
        applicationID: "538615910",
        sa: 1
    },
    loader_config: {
        accountID: "4370424",
        trustKey: "4370424",
        agentID: "538615910",
        licenseKey: "NRJS-ca102f8b56867404464",
        applicationID: "538615910"
    },
    features: [
        Logging
    ]
};
