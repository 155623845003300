import * as React from 'react';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface ILifespanStandardInputProps {
    type?: React.InputHTMLAttributes<unknown>['type'];
    error: boolean;
    id: string;
    label: React.ReactNode;
    value?: unknown;
    helperText?: React.ReactNode;
    onChange: StandardInputProps['onChange'];
    errorTooltip: string;
    endAdornment?: React.ReactElement;
};

export default function LifespanStandardInput(props: ILifespanStandardInputProps) {
    const adornment = (isError: boolean, title: string) => {
        if (isError) {
            return {
                endAdornment: (
                    <Tooltip title={title} arrow>
                        <InputAdornment position='end'>
                            <ErrorOutlineIcon color='error' />
                        </InputAdornment>
                    </Tooltip>
                )
            };
        }

        if (props.endAdornment !== null && props.endAdornment !== undefined) {
            return {
                endAdornment: (
                    <InputAdornment position='end'>
                        {props.endAdornment}
                    </InputAdornment>
                )
            };
        }

        return undefined;
    };

    return (
        <TextField fullWidth variant='standard' type={props.type}
            error={props.error}
            id={props.id}
            label={props.label}
            value={props.value}
            helperText={props.helperText}
            onChange={props.onChange}
            InputProps={adornment(props.error, props.errorTooltip)}
        />
    );
}