import styled from '@emotion/styled';
import { Container, Grid, Paper } from '@mui/material';
import * as React from 'react'
import Colors from '../../styles/Colors.json';
import PaletteColorDescription from './PaletteColorDescription';
import EnsureIsAuthorized from '../../components/EnsureIsAuthorized';

const ColorTitle = styled(Grid)(({}) => ({
    verticalAlign:'middle', lineHeight:'48px', marginLeft:'8px', width:'110px', height:'48px'
}));

export default function BrandingMain() {
    return (
        <EnsureIsAuthorized to='view' the='branding'>
            <Container maxWidth="md">
                <Paper sx={{ backgroundColor: 'white', backgroundSize: 'cover', marginBottom: 4, marginTop: 4, flexGrow: 1 }}>
                    <Grid container spacing={1} paddingBottom={1}>
                        <Grid container item>
                            <ColorTitle>Marca</ColorTitle>
                            <PaletteColorDescription colorPosition={50} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={100} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={200} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={300} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={400} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={500} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={600} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={700} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={800} colorDetails={Colors.brand} />
                            <PaletteColorDescription colorPosition={900} colorDetails={Colors.brand} />
                        </Grid>

                        <Grid container item>
                            <ColorTitle>Complementar</ColorTitle>
                            <PaletteColorDescription colorPosition={50} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={100} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={200} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={300} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={400} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={500} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={600} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={700} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={800} colorDetails={Colors.complementary} />
                            <PaletteColorDescription colorPosition={900} colorDetails={Colors.complementary} />
                        </Grid>

                        <Grid container item>
                            <ColorTitle>Análoga</ColorTitle>
                            <PaletteColorDescription colorPosition={50} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={100} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={200} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={300} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={400} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={500} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={600} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={700} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={800} colorDetails={Colors.analogous} />
                            <PaletteColorDescription colorPosition={900} colorDetails={Colors.analogous} />
                        </Grid>

                        <Grid container item>
                            <ColorTitle>Triádica</ColorTitle>
                            <PaletteColorDescription colorPosition={50} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={100} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={200} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={300} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={400} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={500} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={600} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={700} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={800} colorDetails={Colors.triadic} />
                            <PaletteColorDescription colorPosition={900} colorDetails={Colors.triadic} />
                        </Grid>

                        <Grid container item>
                            <ColorTitle>Adicional</ColorTitle>
                            <PaletteColorDescription colorPosition={50} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={100} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={200} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={300} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={400} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={500} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={600} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={700} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={800} colorDetails={Colors.additional} />
                            <PaletteColorDescription colorPosition={900} colorDetails={Colors.additional} />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </EnsureIsAuthorized>
    );
}