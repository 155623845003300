import { loginRequest } from "../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

export abstract class BaseService {
    private msalClient?: IPublicClientApplication;

    protected constructor(msalClient?: IPublicClientApplication) {
        this.msalClient = msalClient;
    };

    protected async getToken(): Promise<string> {
        if (!this.msalClient) {
            throw 'No authentication client was provided.';
        }

        await this.msalClient.initialize();

        const account = this.msalClient.getActiveAccount();
  
        const response = await this.msalClient.acquireTokenSilent({
          ...loginRequest,
          account: account!
        });
        
        return `Bearer ${response.accessToken}`
    };
}