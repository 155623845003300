import * as React from 'react';
import Colors from '../../styles/Colors.json';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';

export default function ForbiddenErrorPage() {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md">
            <Paper sx={{
                backgroundColor: 'white',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginTop: 4}}>

                <Grid container direction="column" alignItems='center' justifyContent='center'>
                    <Grid item md={12} sx={{pt:{xs:2, md:2}, pl:{xs:3, md:6}, pr:{xs:3, md:6}}}>
                        <Typography fontFamily='Georgia, serif' fontSize={128} fontWeight={600} color={Colors.triadic[900].color}>
                            403
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="h5" paragraph color={Colors.triadic[700].color}>
                            Error - Forbidden
                        </Typography>
                    </Grid>
                    <Grid item md={12} sx={{pt:6}}>
                        <Typography variant="subtitle1" paragraph>
                            Você não tem permissão para acessar a página requisitada.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{pt:2, pb:4}}>
                        <Button variant="contained" startIcon={<DashboardIcon />} onClick={() => navigate("/")}>Voltar para Painel</Button>
                    </Grid>
                </Grid>

            </Paper>
        </Container>
    );
}
