import * as React from 'react';
import { Container, Grid, IconButton } from '@mui/material';
import { CalcRequestContext } from './TransformerLifespanContext';
import LifespanStandardInput from './components/LifespanStandardInput';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const defaultErrorTooltip = 'Massa deve ser maior que zero.';

export interface ITransLifespanOilStepProps {
    setErrorState: (isErrorState: boolean) => void;
}

const isMassInvalid = (mass: number): boolean => {
    return mass <= 0;
}

export default function TransLifespanOilStep(props: ITransLifespanOilStepProps) {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const [ coreMassError, setCoreMassError ] = React.useState(isMassInvalid(calcRequest.inputData.coreMass));
    const [ coilsMassError, setCoilsMassError ] = React.useState(isMassInvalid(calcRequest.inputData.coilsMass));
    const [ tankAndFittingsMassError, setTankAndFittingsMassError ] = React.useState(isMassInvalid(calcRequest.inputData.tankAndFittingsMass));
    const [ oilMassError, setOilMassError ] = React.useState(isMassInvalid(calcRequest.inputData.oilMass));
    const [ oilMassInKilogram, setOilMassInKilogram ] = React.useState(calcRequest.inputData.oilMassSpecification === 0);

    React.useEffect(() => {
        props.setErrorState(coreMassError || coilsMassError || tankAndFittingsMassError || oilMassError);
    }, [coreMassError, coilsMassError, tankAndFittingsMassError, oilMassError]);

    const onCoreMassChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const coreMass = +event.target.value;
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, coreMass: coreMass}});
        setCoreMassError(isMassInvalid(coreMass));
    };

    const onCoilsMassChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const coilsMass = +event.target.value;
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, coilsMass: coilsMass}});
        setCoilsMassError(isMassInvalid(coilsMass));
    };

    const onTankAndFittingsMassChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const tankAndFittingsMass = +event.target.value;
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, tankAndFittingsMass: tankAndFittingsMass}});
        setTankAndFittingsMassError(isMassInvalid(tankAndFittingsMass));
    };

    const onOilMassChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const oilMass = +event.target.value;
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, oilMass: oilMass}});
        setOilMassError(isMassInvalid(oilMass));
    };

    const handleClickSwapOilMass = (): void => {
        var newState = !oilMassInKilogram;

        setOilMassInKilogram(newState);
        setCalcRequest({ ...calcRequest, inputData: {...calcRequest.inputData, oilMassSpecification: newState ? 0 : 1}});
    };

    return (
        <Container component="form" sx={{ paddingTop: 6, paddingBottom: 3 }} noValidate autoComplete="off">
            <Grid container spacing={3}>

                <Grid item xs={4}>
                    <LifespanStandardInput id='coreMass-textField' type='number'
                        error={coreMassError}
                        label='Massa do núcleo'
                        value={calcRequest.inputData.coreMass}
                        onChange={onCoreMassChange}
                        helperText='kg'
                        errorTooltip={defaultErrorTooltip}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LifespanStandardInput id='coilsMass-textField' type='number'
                        error={coilsMassError}
                        label='Massa dos enrolamentos'
                        value={calcRequest.inputData.coilsMass}
                        onChange={onCoilsMassChange}
                        helperText='kg'
                        errorTooltip={defaultErrorTooltip}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LifespanStandardInput id='tankAndFittingsMass-textField' type='number'
                        error={tankAndFittingsMassError}
                        label='Massa do tanque e acessórios'
                        value={calcRequest.inputData.tankAndFittingsMass}
                        onChange={onTankAndFittingsMassChange}
                        helperText='kg'
                        errorTooltip={defaultErrorTooltip}
                    />
                </Grid>

                <Grid item xs={4}>
                    <LifespanStandardInput id='oilMass-textField' type='number'
                        error={oilMassError}
                        label='Massa do óleo'
                        value={calcRequest.inputData.oilMass}
                        onChange={onOilMassChange}
                        helperText={oilMassInKilogram ? 'kg' : 'litros'}
                        errorTooltip={defaultErrorTooltip}
                        endAdornment={
                            <IconButton onClick={handleClickSwapOilMass}>
                                <SwapHorizIcon />
                            </IconButton>
                        }
                    />
                </Grid>

            </Grid>
        </Container>
    );
}