import * as React from 'react';
import { Button, Container, FormControl, FormHelperText, Grid, Input, InputLabel } from '@mui/material';
import { DataGrid, GridColDef, GridRowModel, GridRowSelectionModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CalcRequestContext } from './TransformerLifespanContext';
import { ILoadPeriod, ITransLifespanInputData } from './ITransLifespanCalculationRequest';
import OnsCustomizedMenus, { OnsStandardEvent } from './components/OnsCustomizedMenus';

const onsHotSpotParameters: ILoadPeriod[] = [
    {index: 0, id: 0, load: 1, duration: 600, ambientTemperature: 40, selected: false},
    {index: 1, id: 1, load: 1.2, duration: 240, ambientTemperature: 40, selected: false},
    {index: 2, id: 2, load: 1.4, duration: 30, ambientTemperature: 40, selected: false},
    {index: 3, id: 3, load: 1, duration: 570, ambientTemperature: 40, selected: false},
];

const onsLifespanParameters: ILoadPeriod[] = [
    {index: 0, id: 0, load: 1, duration: 600, ambientTemperature: 30, selected: false},
    {index: 1, id: 1, load: 1.2, duration: 240, ambientTemperature: 30, selected: false},
    {index: 2, id: 2, load: 1.4, duration: 30, ambientTemperature: 30, selected: false},
    {index: 3, id: 3, load: 1, duration: 13530, ambientTemperature: 30, selected: false},
];

export default function TransLifespanLoadStep() {
    const { calcRequest, setCalcRequest } = React.useContext(CalcRequestContext);
    const [ canDelete, setCanDelete ] = React.useState(false);
    
    const columns: GridColDef[] = [
        { field: 'load', headerName: 'Carga (p.u.)', type: 'number', width: 120, editable: true },
        { field: 'duration', headerName: 'Duração (min)', type: 'number', width: 150, editable: true },
        { field: 'ambientTemperature', headerName: 'Temp. ambiente (°C)', type: 'number', width: 170, editable: true },
        { field: 'delete', headerName: '', align: 'right', type: 'actions',
            valueGetter: () => calcRequest.inputData.loadPeriods.length <= 1,
            renderCell: params => (params.api.getCellValue(params.row.id, 'delete') as boolean) ? <></> : <Button variant="text" endIcon={<DeleteOutlineIcon />} onClick={() => removeRowById(params.row.id)}></Button>
        }
    ];

    const removeRowById = (rowId: number): void => {
        const newState = calcRequest.inputData;
        const rowPosition = newState.loadPeriods.findIndex(w => w.id === rowId);
        newState.loadPeriods = [...newState.loadPeriods];
        newState.loadPeriods.splice(rowPosition, 1);

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const addCondition = (): void => {
        const newState = calcRequest.inputData;
        const maxId = Math.max(...newState.loadPeriods.map(l => l.index)) + 1;

        const loadPeriod: ILoadPeriod = {
            index: maxId,
            id: maxId,
            load: 1,
            duration: 450,
            ambientTemperature: 30,
            selected: false
        };

        newState.loadPeriods = [...newState.loadPeriods, loadPeriod];
        setCalcRequest({...calcRequest, inputData: newState});
    };

    const removeSelectedLoadPeriods = (): void => {
        const newState = calcRequest.inputData;
        newState.loadPeriods = newState.loadPeriods.filter(w => !w.selected);

        if (newState.loadPeriods.length === 0) {
            newState.loadPeriods = [{
                index: 0,
                id: 0,
                load: calcRequest.inputData.initialLoad,
                duration: 60,
                ambientTemperature: calcRequest.inputData.initialAmbientTemperature,
                selected: false
            }];
        }

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const applyOnsStandard = (evt: OnsStandardEvent): void => {
        const newState = calcRequest.inputData;

        if (evt.selectedIndex === 0) {
            newState.loadPeriods = onsHotSpotParameters;
        } else {
            newState.loadPeriods = onsLifespanParameters;
        }

        setCalcRequest({...calcRequest, inputData: newState});
    };

    const setSelectedRows = (evt: GridRowSelectionModel): void => {
        const newState = calcRequest.inputData;

        newState.loadPeriods.forEach(w => {
            w.selected = evt.includes(w.id);
        });

        setCanDelete(evt.length > 0)
        setCalcRequest({...calcRequest, inputData: newState});
    };

    const loadPeriodsProcessRowUpdate = (newRow: GridRowModel): GridRowModel => {
        const newState = calcRequest.inputData;

        const loadPeriod = newState.loadPeriods.find(l => l.id === newRow.id);

        if (loadPeriod) {
            loadPeriod.load = newRow.load;
            loadPeriod.duration = newRow.duration;
            loadPeriod.ambientTemperature = newRow.ambientTemperature;
        }

        setCalcRequest({...calcRequest, inputData: newState});

        return newRow;
    };

    const setInputData = (inputData: ITransLifespanInputData) : void => {
        setCalcRequest({...calcRequest, inputData: inputData});
    };

    return (
        <Container sx={{ paddingTop: 6, paddingBottom: 3 }}>
            <Grid container spacing={1} paddingBottom={3} paddingLeft={3} >
                <Grid item xs='auto'>
                    <Button variant="contained" startIcon={<AddIcon />} onClick={addCondition}>Acrescentar</Button>
                </Grid>
                <Grid item xs='auto'>
                    <Button variant="contained" disabled={!canDelete} startIcon={<DeleteIcon />} onClick={removeSelectedLoadPeriods}>Remover</Button>
                </Grid>
                <Grid item xs='auto'>
                    <OnsCustomizedMenus onClick={applyOnsStandard}/>
                </Grid>
            </Grid>

            <Container>
                <Grid container spacing={3} direction='row' alignItems='stretch'>
                    <Grid item xs>
                        <DataGrid
                            rows={calcRequest.inputData.loadPeriods}
                            columns={columns}
                            initialState={{ pagination: { paginationModel: { pageSize: 5 }} }}
                            isRowSelectable={() => calcRequest.inputData.loadPeriods.length > 1}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={setSelectedRows}
                            processRowUpdate={loadPeriodsProcessRowUpdate}
                            style={{ height: '100%' }}
                            />
                    </Grid>

                    <Grid item xs={4}>
                        <Container component="form" noValidate autoComplete="off">
                            <Grid container spacing={3}>

                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="initialLoad-input">Carga inicial</InputLabel>
                                        <Input id="initialLoad-input" aria-describedby='initialLoad-helper' type='number'
                                            value={calcRequest.inputData.initialLoad} onChange={evt => setInputData({ ...calcRequest.inputData, initialLoad: +evt.target.value })}/>
                                        <FormHelperText id="initialLoad-helper">percentual</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="initialAmbientTemperature-input">Temperatura ambiente inicial</InputLabel>
                                        <Input id="initialAmbientTemperature-input" aria-describedby="initialAmbientTemperature-helper" type='number'
                                            value={calcRequest.inputData.initialAmbientTemperature} onChange={evt => setInputData({ ...calcRequest.inputData, initialAmbientTemperature: +evt.target.value })}/>
                                        <FormHelperText id="initialAmbientTemperature-helper">°C</FormHelperText>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Container>
                    </Grid>

                </Grid>
            </Container>

        </Container>
    );
}