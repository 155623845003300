import axios from "axios";
import { BaseService } from "./BaseService";
import { IPublicClientApplication } from "@azure/msal-browser";

export type HealthcheckResponseFunction = (response: void | any) => void;

export interface IHealthCheckService {
    getSilent(predicate?: undefined | null | HealthcheckResponseFunction): Promise<void>
}

export class HealthCheckService extends BaseService implements IHealthCheckService {
    constructor(msalClient?: IPublicClientApplication) {
        super(msalClient);
    }
    
    public getSilent(predicate?: HealthcheckResponseFunction | null | undefined): Promise<void> {
        return axios
            .get<any>('health')
            .catch()
            .then(function (response) {
                if (predicate) {
                    return predicate(response?.data);
                }
            });
    }
}