import axios from "axios";
import { ITransLifespanService, TransLifespanService } from "./TransLifespanService";
import { IPublicClientApplication } from "@azure/msal-browser";
import { IUserService, UserService } from "./UserService";
import { HealthCheckService, IHealthCheckService } from "./HealthcheckService";

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;

export const CreateHealthcheckService = (): IHealthCheckService =>
    new HealthCheckService(undefined);

export const CreateUserService = (msalClient: IPublicClientApplication): IUserService =>
    new UserService(msalClient);

export const CreateTransLifespanService = (msalClient: IPublicClientApplication): ITransLifespanService =>
    new TransLifespanService(msalClient);
