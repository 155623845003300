import { createContext } from 'react';
import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability';

export const EmptyAbility = createMongoAbility();

export const AbilityContext = createContext({
    ability: EmptyAbility,
    setAbility: (() => {}) as React.Dispatch<React.SetStateAction<MongoAbility>>
});

export const createAbility = (permissions: string[]) => {
    const { can, build } = new AbilityBuilder(createMongoAbility);

    if (permissions !== null && permissions !== undefined) {
        let shouldViewAdminNav = false;

        permissions.forEach(function (permission) {
            switch (permission.toLowerCase()) {
                case 'update.user.self':
                    can('update', 'profile');
                    break;
                case 'view.branding':
                    can('view', 'branding');
                    shouldViewAdminNav = true;
                    break;
                case 'manage.user.all':
                    can('manage', 'users');
                    shouldViewAdminNav = true;
                    break;
                case 'view.devportal':
                    can('view', 'devportal');
                    break;
            }
        });

        if (shouldViewAdminNav) {
            can('view', 'adminnav')
        }
    }

    return build();
};
