import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Layout from './views/Layout';
import Dashboard from './views/dashboard/Dashboard';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import TransLifespanProjectLoad from './views/transLifespan/TransLifespanProjectLoad';
import TransLifespanProjectEdit from './views/transLifespan/TransLifespanProjectEdit';
import TransLifespanMain from './views/transLifespan/TransLifespanMain';
import Contact from './views/Contact';
import BrandingMain from './views/branding/BrandingMain';
import AuthenticationProvider from './components/AuthenticationProvider';
import AllUsersMain from './views/users/AllUsersMain';
import UnauthorizedErrorPage from './views/error/UnauthorizedErrorPage';
import ForbiddenErrorPage from './views/error/ForbiddenErrorPage';
import NotFoundErrorPage from './views/error/NotFoundErrorPage';
import UserProfile from './views/users/UserProfile';

export interface IApp {
    msal: PublicClientApplication;
};

const TransLifespanLandingOrProjectLoad = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <TransLifespanProjectLoad />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <TransLifespanMain />
            </UnauthenticatedTemplate>
        </>
    );
}

const TransLifespanLandingOrProjectEdit = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <TransLifespanProjectEdit />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <TransLifespanMain />
            </UnauthenticatedTemplate>
        </>
    );
}

export default function App(props: IApp) {
    return (
        <MsalProvider instance={props.msal}>
            <AuthenticationProvider>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="translifespan" element={<TransLifespanLandingOrProjectLoad />} />
                        <Route path="translifespan/:projectId" element={<TransLifespanLandingOrProjectEdit />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="branding" element={<BrandingMain />} />
                        <Route path="users" element={<AllUsersMain />} />
                        <Route path="userprofile" element={<UserProfile />} />
                        <Route path="forbidden" element={<ForbiddenErrorPage />} />
                        <Route path="unauthorized" element={<UnauthorizedErrorPage />} />
                        <Route path="notfound" element={<NotFoundErrorPage />} />
                        <Route path="*" element={<NotFoundErrorPage />} />
                    </Route>
                </Routes>
            </AuthenticationProvider>
        </MsalProvider>
    );
}
