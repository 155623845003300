import * as React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { CalcRequestContext } from '../TransformerLifespanContext';
import * as Branding from './TransLifespanBranding';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function TransLifespanLoadGraph() {
    const { calcRequest } = React.useContext(CalcRequestContext);

    const dataPoints = React.useMemo(() => {
        const result: Array<{x: number, y: number}> = [];
        result.push({x: 0, y: calcRequest.inputData.initialLoad});

        let parcialSum = 0;

        for (let index = 0; index < calcRequest.inputData.loadPeriods.length; index++) {
            const element = calcRequest.inputData.loadPeriods[index];
            
            const n = Math.floor(element.duration / calcRequest.inputData.timeInterval);
            parcialSum += element.duration;

            result.push({x: result[result.length - 1].x + calcRequest.inputData.timeInterval, y: element.load});
            result.push({x: result[result.length - 1].x + calcRequest.inputData.timeInterval * (n - 1), y: element.load});

            if (result[result.length - 1].x < parcialSum) {
                result.push({x: parcialSum, y: element.load});
            }
        }

        return result;
    }, [calcRequest]);

    const maxLoad = React.useMemo(() => 
        dataPoints.reduce((previous, current) => (previous && previous.y > current.y) ? previous : current).y
    , [calcRequest]);

    const graphOptions = React.useMemo(() => ({
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Carga',
            },
        },
        responsive: true,
        scales: {
            x: {
                min: 0,
                max: dataPoints[dataPoints.length - 1].x,
                title: {
                    display: true,
                    text: 'Tempo [min]'
                }
            },
            y: {
                min: 0,
                max: maxLoad + 0.1,
                ticks: {
                    stepSize: 0.1
                },
                title: {
                    display: true,
                    text: 'Carga [p.u.]'
                }
             }
        }
    }), [calcRequest]);

    const graphData = React.useMemo(() => ({
        datasets: [{
            backgroundColor: Branding.GetGraphColor(0),
            borderColor: Branding.GetGraphColor(0),
            data: dataPoints,
            label: 'Carga',
            showLine: true,
        }],
    }), [calcRequest]);

    return (
        <Scatter options={graphOptions} data={graphData} />
    );
}
