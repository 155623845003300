import React from "react";
import UserProfileLoggedInMenu from './profile/UserProfileLoggedInMenu';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import UserProfileLoggedOutMenu from "./profile/UserProfileLoggedOutMenu";

export default function UserProfileMenu() {
    return (
        <>
            <AuthenticatedTemplate>
                <UserProfileLoggedInMenu />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UserProfileLoggedOutMenu />
            </UnauthenticatedTemplate>
        </>
    );
};
    