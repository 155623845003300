import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import { AbilityContext } from '../components/Can';

export function NavigationListItems() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>Navegação</ListSubheader>
            <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Painel" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/translifespan")}>
                <ListItemIcon>
                    <AvTimerIcon />
                </ListItemIcon>
                <ListItemText primary="Cálculo de vida útil" />
            </ListItemButton>
        </React.Fragment>
    );
};

export function AdminListItems() {
    const navigate = useNavigate();
    const ability = React.useContext(AbilityContext);

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>Admin</ListSubheader>
            <ListItemButton onClick={() => navigate("/branding")} disabled={ability.cannot('view', 'branding')}>
                <ListItemIcon>
                    <FormatColorFillIcon />
                </ListItemIcon>
                <ListItemText primary="Branding" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/users")} disabled={ability.cannot('manage', 'users')}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Usuários" />
            </ListItemButton>
        </React.Fragment>
    );
};

export function SuportListItems() {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <ListSubheader component="div" inset>Suporte</ListSubheader>
            <ListItemButton onClick={() => navigate("/contact")}>
                <ListItemIcon>
                    <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText primary="Contato" />
            </ListItemButton>
        </React.Fragment>
    );
};
