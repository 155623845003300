import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';

export interface ILocalDataTooltipProps {
    date: any | undefined;
};

export default function LocalDataTooltip(props: ILocalDataTooltipProps) {
    return props.date === null || props.date === undefined
        ? <span></span>
        : <Tooltip title={new Date(props.date).toLocaleString()}>
            <span>{new Date(props.date).toLocaleDateString()}</span>
        </Tooltip>;
};
